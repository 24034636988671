import { Type } from 'class-transformer';
import { ValidateNested, IsArray } from 'class-validator';

import { GetDocumentDto } from './get-document.dto';

export class GetDocumentsDto {
  @IsArray()
  @ValidateNested({ each: true })
  @Type(() => GetDocumentDto)
  documents: GetDocumentDto[];
}

import { createSlice } from '@reduxjs/toolkit';

import { initialState, absencesAdapter } from './absences.state';
import { absencesReducers, creationReducers } from './actions';

export const absences = createSlice({
  name: 'absences',
  initialState: absencesAdapter.getInitialState(initialState),
  reducers: {},
  extraReducers: (builder) => {
    creationReducers(builder);
    absencesReducers(builder);
  },
});

export const absencesReducer = absences.reducer;

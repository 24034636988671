// eslint-disable-next-line max-classes-per-file
import { Type } from 'class-transformer';
import { IsArray, IsNotEmpty, ValidateNested } from 'class-validator';

import {
  NoOverlapValidator,
  MinimumDurationValidator,
  WeekendExclusionCheckValidator,
  WorkingDayCheckValidator,
  MinDateValidator,
  MaxDateValidator,
  MaxYearValidator,
  MaximumDurationValidator,
  SameMonthValidator,
  VacationAvailableValidator,
  DivisibleByValidator,
  HolydaysExclusionCheckValidator,
  MinHiringDayValidator,
  MinWeekendDaysValidator,
  NumberValidator,
  MinSpecificWeekendDaysValidator,
  VacationMin5daysBetweenNotWorkdaysValidator,
} from '@app/gen/events';

import { SingleWorkingDayCheckValidator } from './single-working-day-check-validator.dto';
import { SingleHolydaysExclusionCheckValidator } from './single-holydays-exclusion-check-validator.dto';

export type Validator =
  | NoOverlapValidator
  | MinimumDurationValidator
  | WeekendExclusionCheckValidator
  | WorkingDayCheckValidator
  | SingleWorkingDayCheckValidator
  | SingleHolydaysExclusionCheckValidator
  | MinDateValidator
  | MaxDateValidator
  | MaximumDurationValidator
  | SameMonthValidator
  | VacationAvailableValidator
  | DivisibleByValidator
  | HolydaysExclusionCheckValidator
  | MinHiringDayValidator
  | MinWeekendDaysValidator
  | MaxYearValidator
  | NumberValidator
  | MinSpecificWeekendDaysValidator
  | VacationMin5daysBetweenNotWorkdaysValidator;

export class NodeValidatorsResponse {
  @IsNotEmpty()
  @IsArray()
  @ValidateNested({ each: true })
  @Type(() => class {}, {
    discriminator: {
      property: 'type',
      subTypes: [
        { value: NoOverlapValidator, name: 'no_overlap' },
        { value: MinimumDurationValidator, name: 'min_duration' },
        { value: WeekendExclusionCheckValidator, name: 'weekend_exclusion' },
        { value: WorkingDayCheckValidator, name: 'working_day' },
        { value: SingleWorkingDayCheckValidator, name: 'single_working_day' },
        {
          value: SingleHolydaysExclusionCheckValidator,
          name: 'single_holydays_exclusion',
        },
        { value: MinDateValidator, name: 'min_date' },
        { value: MaxDateValidator, name: 'max_date' },
        { value: MaximumDurationValidator, name: 'max_duration' },
        { value: SameMonthValidator, name: 'same_month' },
        { value: VacationAvailableValidator, name: 'vacation_available' },
        { value: DivisibleByValidator, name: 'divisible_by' },
        { value: HolydaysExclusionCheckValidator, name: 'holydays_exclusion' },
        { value: MaxYearValidator, name: 'max_year' },
        {
          value: MinWeekendDaysValidator,
          name: 'min_weekend_days',
        },
        {
          value: NumberValidator,
          name: 'number_validator',
        },
      ],
    },
    keepDiscriminatorProperty: true,
  })
  validators: Validator[];
}

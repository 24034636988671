import {
  createEntityAdapter,
  EntityState,
  SerializedError,
} from '@reduxjs/toolkit';

import { AppError } from '@vk-hr-tek/core/error';
import { Filter } from '@vk-hr-tek/core/filter';
import {
  VacationAvailableDaysResponse,
  VacationScheduleMyDeadlineResponse,
} from '@vk-hr-tek/app/app/gen/vacations';
import { UnitNodeLabeled, UnitTypeOption } from '@vk-hr-tek/core/units';

import { RootState } from '@app/store';
import { AbsenceCompanyItem, AbsenceListGroup } from '@app/gen/absences';
import {
  AssignableRoles,
  CreateEventCompanyItem,
  CreateEventOption,
  CreateEventTypeOptionsCopyAttribute,
  CreateEventTypeOptionsCopyDocument,
  EventBatchEmployee,
  EventBatchItem,
  EventTypeItem,
  EmployeeItem,
} from '@app/gen/events';

export interface AbsencesState {
  currentIds: string[];
  availableDays: {
    status: 'idle' | 'loading' | 'complete' | 'failed';
    error: AppError | null;
    data: VacationAvailableDaysResponse;
  };
  companies: {
    status: 'idle' | 'loading' | 'complete' | 'failed';
    items: CreateEventCompanyItem[];
    error: AppError | null;
  };
  absenceCompanies: {
    status: 'idle' | 'loading' | 'complete' | 'failed';
    items: AbsenceCompanyItem[];
    error: AppError | null;
  };
  absenceList: {
    status: 'idle' | 'loading' | 'complete' | 'failed';
    additionalStatus:
      | 'idle'
      | 'loading'
      | 'loadingAfterFail'
      | 'complete'
      | 'failed';
    items: AbsenceListGroup[];
    error: AppError | null;
    total: number;
    totalDocuments: number | null;
    totalMy?: number;
    totalMyTeam?: number;
    finished: number;
    filters: Record<string, Filter[]>;
  };
  deadline: {
    status: 'idle' | 'loading' | 'complete' | 'failed';
    info: VacationScheduleMyDeadlineResponse;
    error: AppError | null;
  };
  eventCreationError: AppError | null;
  creation: {
    employees: {
      status: 'idle' | 'loading' | 'complete' | 'failed';
      list: EmployeeItem[];
    };
    status: 'idle' | 'loading' | 'complete' | 'failed';
    options: CreateEventOption[];
    items: CreateEventCompanyItem[];
    error: AppError | null;
    canCreateBatch: boolean;
    canCreateForAnother: boolean;
    eventTypes: EventTypeItem[] | null;
    employeeEventTypes: EventTypeItem[] | null;
    selected: {
      company: { id: string; name: string };
      eventType: { id: string; name: string; documentInfoRequired: boolean };
      employees: EventBatchEmployee[];
      existingBatches: EventBatchItem[];
      unitTypeOptions?: UnitTypeOption[];
      rootUnit?: UnitNodeLabeled | null;
      employeesLoadingError?: boolean;
    } | null;
  };
  createEventTypeOptions: {
    status: 'idle' | 'loading' | 'complete' | 'failed';
    options: Record<
      string,
      {
        assignableRoles?: AssignableRoles;
        copyDocuments?: CreateEventTypeOptionsCopyDocument[];
        copyAttributes?: CreateEventTypeOptionsCopyAttribute[];
      }
    >;
    error: AppError | null;
  };
  canCreateEvent: boolean;
}

export interface AbsencesWithRootState extends RootState {
  absences: AbsencesState & EntityState<AbsenceListGroup>;
}

export const initialState: AbsencesState = {
  currentIds: [],
  availableDays: {
    status: 'idle',
    data: { available_days_by_types: [] },
    error: null,
  },
  companies: {
    status: 'idle',
    items: [],
    error: null,
  },
  absenceCompanies: {
    status: 'idle',
    items: [],
    error: null,
  },
  absenceList: {
    additionalStatus: 'idle',
    status: 'idle',
    items: [],
    error: null,
    total: 0,
    totalDocuments: null,
    finished: 0,
    filters: {},
  },
  deadline: {
    status: 'idle',
    info: {},
    error: null,
  },
  eventCreationError: null,
  creation: {
    employees: {
      status: 'idle',
      list: [],
    },
    status: 'idle',
    options: [],
    items: [],
    eventTypes: null,
    employeeEventTypes: null,
    error: null,
    canCreateBatch: false,
    canCreateForAnother: false,
    selected: null,
  },
  createEventTypeOptions: {
    status: 'idle',
    options: {},
    error: null,
  },
  canCreateEvent: false,
};

export const startLoading = (state: AbsencesState) => {
  state.absenceList.status = 'loading';
  state.absenceList.error = null;
  state.absenceList.total = 0;
  state.absenceList.finished = 0;
  state.currentIds = [];
};

export const completeLoading = (state: AbsencesState) => {
  state.absenceList.status = 'complete';
  state.absenceList.error = null;
};

export const setError = (
  state: AbsencesState,
  { payload, error }: { payload?: AppError; error: SerializedError },
) => {
  state.absenceList.status = 'failed';
  state.absenceList.error =
    payload ||
    ({
      info: (error && error.message) || 'Unknown error',
      status: 500,
      source: 'client',
      title: 'Internal client error',
    } as AppError);
};

export const setEventCreationError = (
  state: AbsencesState,
  { payload, error }: { payload?: AppError; error: SerializedError },
) => {
  state.eventCreationError =
    payload ||
    ({
      info: (error && error.message) || 'Unknown error',
      status: 500,
      source: 'client',
      title: 'Internal client error',
    } as AppError);
};

export const absencesAdapter = createEntityAdapter<AbsenceListGroup>({
  selectId: (absence) => {
    return absence.employee.id;
  },
});

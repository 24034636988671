import { IsNotEmpty, IsUUID } from 'class-validator';

export const notEmptyOptions = {
  message: 'Обязательное поле',
};

export class DetailPolicyVersion {
  @IsNotEmpty(notEmptyOptions)
  policy_version_id: string;

  @IsUUID()
  @IsNotEmpty(notEmptyOptions)
  employee_id: string;

  @IsNotEmpty(notEmptyOptions)
  signature: string;
}

import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  divider: {
    '&::before, &::after': {
      content: "''",
      flex: 1,
      height: '1px',
      backgroundColor: theme.tokens.colors.stroke.primary,
    },
  },
}));

import { IsBoolean, IsNotEmpty, IsUUID } from 'class-validator';

export class UpdateManagerSettings {
  @IsUUID(undefined, {
    message: 'Вы указали неверные настройки согласования',
  })
  @IsNotEmpty({
    message: 'Вы указали неверные настройки согласования',
  })
  id: string;

  @IsBoolean({
    message: 'Вы указали неверные настройки согласования',
  })
  @IsNotEmpty({
    message: 'Вы указали неверные настройки согласования',
  })
  value: boolean;
}

import { Type } from 'class-transformer';
import { IsArray, IsNotEmpty, ValidateNested, IsString } from 'class-validator';

import {
  notEmptyOptions,
  DetailPolicyVersion,
} from './detail-policy-version.dto';

export class AcceptCryptoLocalDto {
  @Type(() => DetailPolicyVersion)
  @ValidateNested({ each: true })
  @IsArray()
  @IsNotEmpty(notEmptyOptions)
  policyVersions: DetailPolicyVersion[];

  @IsString()
  @IsNotEmpty()
  certificateId: string;
}

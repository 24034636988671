import {
  IsIn,
  IsNotEmpty,
  IsOptional,
  IsString,
  IsUUID,
} from 'class-validator';

import { FormAttributesRequest } from '../../types';

export class UnepLocalDto {
  @IsNotEmpty()
  @IsUUID()
  eventId: string;

  @IsNotEmpty()
  @IsUUID()
  documentId: string;

  @IsNotEmpty()
  @IsUUID()
  nodeId: string;

  @IsNotEmpty()
  @IsString()
  certificateId: string;

  attributes?: FormAttributesRequest;

  @IsOptional()
  @IsString()
  tsp?: string;

  @IsOptional()
  @IsString()
  @IsIn(['api', 'browser_plugin'])
  hashSource?: 'api' | 'browser_plugin';
}

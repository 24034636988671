import { injectable } from 'inversify';

@injectable()
export class AuthInstanseService {
  private id = '';

  get(): string {
    return this.id;
  }

  save(instanseId: string) {
    this.id = instanseId;
  }
}

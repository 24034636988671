/* eslint-disable @typescript-eslint/no-explicit-any */
// eslint-disable-next-line max-classes-per-file
import { injectable } from 'inversify';
import { Transform } from 'class-transformer';

import { Calendar } from '../calendar';

import { Filter, FilterType } from './filter.entity';
import { IsFilters } from './is-filters.validator';
import { filtersTransform } from './filters.transform';

/**
 * Класс содержит свойство 'filters'
 * @typedef FiltersClass
 * @type {object}
 * @property {Object.<string, string | string[] | boolean>} filters
 */

@injectable()
export class FilterService {
  constructor(private calendar: Calendar) {}

  /**
   * Возвращает новый класс с фильтрами
   * Расширяющая часть описана типом {@link FiltersClass}
   * @template {class} T
   * @param {abstract new () => T} Dto Расширяемый класс
   * @param {Array} filters Фильтры
   * @returns {new () => T & FiltersClass}
   */
  buildDto<T extends new (...args: any[]) => Record<string, any>>(
    Dto: T,
    filters: Filter[],
  ) {
    class FiltersDto extends Dto {
      @Transform(filtersTransform(filters))
      @IsFilters(filters)
      filters: {
        [key: string]: string | string[] | boolean;
      };
    }

    return FiltersDto;
  }

  /** Преобразует даты начала и окончания для Dateable значения
   * Если значение имеет тип отличный от Dateable, то никаких изменений не вносится
   * @param {number | boolean | string | Dateable} value Преобразуемое значение
   * @returns {void}
   *  */
  sanitizeDates(value: FilterType[string]) {
    if (typeof value !== 'object' || !(value.start ?? value.end)) {
      return;
    }

    if (value.start) {
      value.start = this.calendar.startOfDay(new Date(value.start));
    }
    if (value.end) {
      value.end = this.calendar.endOfDay(new Date(value.end));
    }
  }
}

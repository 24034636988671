import React from 'react';

import { useTheme } from '@material-ui/core';

export const VkDownloadIcon = () => {
  const theme = useTheme();

  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        width="40"
        height="40"
        rx="12"
        fill={theme.tokens.colors.original.brand.primary}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.002 10C20.499 10 20.902 10.4029 20.902 10.9V21.9017L25.4644 17.2707C25.8133 16.9166 26.3831 16.9124 26.7372 17.2612C27.0913 17.61 27.0955 18.1799 26.7467 18.534L20.6431 24.7292C20.4737 24.9011 20.2424 24.9978 20.0011 24.9976C19.7597 24.9973 19.5286 24.9002 19.3596 24.728L13.27 18.5225C12.9218 18.1677 12.9272 17.5979 13.282 17.2498C13.6367 16.9016 14.2066 16.907 14.5547 17.2617L19.102 21.8955V10.9C19.102 10.4029 19.5049 10 20.002 10ZM12.0024 28.1C12.0024 27.6029 12.4054 27.2 12.9024 27.2H27.1015C27.5985 27.2 28.0015 27.6029 28.0015 28.1C28.0015 28.597 27.5985 29 27.1015 29H12.9024C12.4054 29 12.0024 28.597 12.0024 28.1Z"
        fill="white"
      />
    </svg>
  );
};

import React from 'react';

import { Box } from '@vk-hr-tek/ui/Box';
import { Typography } from '@vk-hr-tek/ui/Typography';

import { useDispatch, useSelector } from '@app/hooks';

import { Button } from '../../../ui/Button';
import { redirectToQr, selectAuthInstance } from '../../../slice';
import { Page } from '../../../ui/page';
import { AdditionalAuthButton } from '../../../widgets';

import useStyles from './styles';

export const Greeting = () => {
  const dispatch = useDispatch();
  const classes = useStyles();

  const { button_color: buttonColor, button_text: buttonText } =
    useSelector(selectAuthInstance);

  const handleClickButton = () => {
    dispatch(redirectToQr());
  };

  return (
    <Box className={classes.greeting}>
      <Page title="Добро пожаловать!" theme="ali">
        <Typography align="center" className="aqa_reg_header">
          Это сервис для электронного оформления кадровых документов (заявления
          на отпуск и любые другие)
        </Typography>
        <Box mt="40">
          <Box display="flex" justifyContent="center">
            <Button
              color={buttonColor && `#${buttonColor}`}
              onClick={handleClickButton}
              fullWidth
            >
              {buttonText || 'Войти'}
            </Button>
          </Box>
        </Box>
        <AdditionalAuthButton />
      </Page>
      <div className={classes.mock} />
    </Box>
  );
};

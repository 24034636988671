import React from 'react';

import { Switch, Route, useRouteMatch } from 'react-router-dom';

import { PageError } from '../../../layout';
import { AuthById } from '../AuthById';
import { AuthList } from '../AuthList';

import { Login } from './Login';
import { Register } from './Register';
import { VerifyAuthCode } from './VerifyAuthCode';

export const Esia = () => {
  const match = useRouteMatch();

  return (
    <Switch>
      <Route exact path={`${match.url}/login`}>
        <Login />
      </Route>
      <Route exact path={`${match.url}/register`}>
        <Register />
      </Route>
      <Route exact path={`${match.url}/result`}>
        <VerifyAuthCode />
      </Route>
      <Route exact path={`${match.url}/auth-list`}>
        <AuthList />
      </Route>
      <Route path={`${match.url}/:id`}>
        <AuthById />
      </Route>
      <Route path={`${match.url}`}>
        <div>
          <PageError status={404} />
        </div>
      </Route>
    </Switch>
  );
};

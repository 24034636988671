import { useEffect, useState } from 'react';

export const useDetectAdBlock = () => {
  const [adBlockDetected, setAdBlockDetected] = useState(false);

  const url = window.REACT_APP_VKHRTEK_ADDBLOCK_DETECTOR_URL;

  useEffect(() => {
    if (url) {
      fetch(url, {
        method: 'HEAD',
        mode: 'no-cors',
        cache: 'no-store',
      }).catch(() => {
        setAdBlockDetected(true);
      });
    }
  }, [url]);

  return adBlockDetected;
};

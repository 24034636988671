import { IsArray, IsNotEmpty, IsUUID } from 'class-validator';

import { FilterType } from '@vk-hr-tek/core/filter';

export class GetEventsPdfDto {
  @IsUUID(undefined, { each: true })
  @IsArray()
  @IsNotEmpty()
  event_ids: string[];

  filters?: FilterType;
}

import {
  createAsyncThunk,
  createAction,
  ActionReducerMapBuilder,
} from '@reduxjs/toolkit';
import { classToPlain } from 'class-transformer';

import { QueryService } from '@vk-hr-tek/core/query';
import { History } from '@vk-hr-tek/core/history';
import { AppError } from '@vk-hr-tek/core/error';

import { PassVerifyResponse } from '@app/gen/pass';

import { AuthAliPassCodeService, AuthRouterService } from '../../../services';
import {
  AuthState,
  startLoading,
  completeLoading,
  setError,
  ThunkExtra,
} from '../../auth.state';

export const setPassCode = createAction<string | null>('auth/setPassCode');

export const verifyPassCode = createAsyncThunk<
  PassVerifyResponse,
  string | undefined,
  ThunkExtra
>(
  'auth/VerifyPassCode',
  async (authInstanceId, { rejectWithValue, dispatch, extra: { inject } }) => {
    const router = inject(AuthRouterService);
    const history = inject<History>(History);

    try {
      const { code: passCode } = inject(QueryService).parse(
        history.location.search,
      );

      dispatch(setPassCode(passCode));

      const result = await inject(AuthAliPassCodeService).verifyPassCode({
        authInstanceId,
        passCode,
      });

      if (result.status === 'registration') {
        router.redirectToRegister(authInstanceId);
      } else if (result.status === 'phone_change') {
        router.redirectToPhoneChange(authInstanceId);
      } else {
        router.redirectToAliLogin();
      }

      return result;
    } catch (err) {
      return rejectWithValue(classToPlain(err) as AppError);
    }
  },
);

export const aliCodeReducers = (
  builder: ActionReducerMapBuilder<AuthState>,
) => {
  builder.addCase(setPassCode, (state, action) => {
    state.passCode = action.payload;
  });
  builder.addCase(verifyPassCode.pending, (state) => {
    startLoading(state);
  });
  builder.addCase(verifyPassCode.fulfilled, (state, action) => {
    completeLoading(state);
    state.values.phone = action.payload.phone_mask || null;
    state.stage = 'init';
  });
  builder.addCase(verifyPassCode.rejected, (state) => {
    state.stage = 'error';
    setError(state, {
      payload: {
        title: 'Ошибка при чтении QR-кода',
        description:
          'При чтении QR-кода произошла ошибка. Попробуйте <a href="/auth/qr">отсканировать код еще раз</a>',
      },
    });
  });
};

import { injectable } from 'inversify';
import { format, parseISO } from 'date-fns';

import { t } from '@vk-hr-tek/core/translations/t';
import type {
  FlowTreeNode,
  FlowTreeNodeState,
  FlowTreeNodeTooltip,
  Executor,
} from '@vk-hr-tek/core/flow';
import { AppError } from '@vk-hr-tek/core/error';

import type {
  EventNodeFlow,
  EventNodeFlowActor,
  NodeAction,
  Transition,
} from '@app/gen/events';

const REJECT_ACTION_TYPE = ['return'];

const TRIVIO_ACTION_TYPE = [
  'system_booking_hook',
  'system_booking_approve',
  'system_booking_trip_create',
  'system_booking_trip_ordering',
  'system_booking_limits_exceeded_approve',
  'system_booking_trip_limit_approved',
];

@injectable()
export class EventsFlowMapper {
  private nodes: EventNodeFlow[];

  private constrictions: Set<string>;

  private inPaper: boolean;

  private paperNodeId: number;

  private action: NodeAction;

  private setNodeAction(actions: NodeAction[]): void {
    const action = actions.find(({ is_primary }) => is_primary);

    if (action) {
      this.action = action;
    }
  }

  private mapStatus(
    status: 'finished' | 'skipped' | 'active' | 'canceled' | 'inactive',
  ): FlowTreeNodeState {
    switch (status) {
      case 'finished':
        return 'success';
      case 'skipped':
        return 'skipped';
      case 'canceled':
        return 'rejected';
      case 'active':
        return 'active';
      case 'inactive':
      default:
        return 'feature';
    }
  }

  private getTooltipComment(
    cancelReason:
      | 'undefined'
      | 'node_deadline'
      | 'event_deadline'
      | 'manual_employee'
      | 'manual_company'
      | 'dismiss'
      | 'workflow_change',
    date?: string,
  ): string | string[] | null {
    switch (cancelReason) {
      case 'node_deadline':
      case 'event_deadline': {
        switch (this.action.type) {
          case 'ukep_sign':
          case 'ukep_sign_batch':
          case 'unep_sign':
          case 'unep_sign_batch':
          case 'pep_sign':
          case 'pep_sign_batch':
            return 'В связи с тем, что документ(ы) не были подписаны в срок, установленный работодателем для подписания электронной подписью';
          case 'upload':
          case 'generate_document_from_template':
            return 'Отменена в связи с тем, что документ не был добавлен в срок, установленный работодателем';
          case 'accept':
          case 'system':
            return 'Отменена в связи с тем, что заявка не была обработана в срок, установленный работодателем';

          default:
            return '';
        }
      }
      case 'dismiss':
        return date
          ? [
              t('event.mappers.flow.cancelForEmployeeDismissalText'),
              `Дата увольнения: ${this.dateFormat(date, true)}`,
            ]
          : t('event.mappers.flow.byEmployeeDismissalText');
      case 'workflow_change':
        return 'Так как компания внесла изменения в порядок оформления. Заявку необходимо создать заново. Приносим извинения за доставленные неудобства.';
      default:
        return null;
    }
  }

  private getTooltipInfo(
    actions: NodeAction[],
    nodeDocuments: string[],
  ): string[] {
    const fromActions = actions.reduce(
      (res: string[], { documents, extra }) => {
        if (extra?.document_type_name) {
          if (!res.includes(extra.document_type_name)) {
            res.push(extra.document_type_name);
          }

          return res;
        }

        return documents
          ? documents.reduce((r, { document_type_name: name }) => {
              if (!r.includes(name)) {
                r.push(name);
              }
              return r;
            }, res)
          : res;
      },
      [],
    );

    return fromActions.length ? fromActions : nodeDocuments;
  }

  private rawNodeToResultNode(
    node: EventNodeFlow,
    startNode = false,
  ): FlowTreeNode {
    this.setNodeAction(node.actions);

    if (startNode) {
      return this.toStartNode(node);
    }

    if (this.action.type === 'system' && !node.transitions?.length) {
      return this.toFinalNode(node);
    }

    const {
      node_id: id,
      status,
      actor,
      documents,
      activity_date: date,
      name,
    } = node;
    const state = this.mapStatus(status);

    const allExecutors = state === 'active' || state === 'skipped' || !date;

    const dataTitle = TRIVIO_ACTION_TYPE.includes(this.action.type)
      ? name
      : this.getNodeTitle();

    const returnedData =
      this.inPaper && state === 'feature'
        ? this.getPaperNode(node)
        : ({
            id,
            title: dataTitle,
            info: status === 'active' ? documents : [],
            enabled: status !== 'inactive',
            state: state,
            tooltip: this.getTooltip(node),
          } as FlowTreeNode);

    if (allExecutors) {
      returnedData.executors = this.getExecutorsData();
    } else {
      returnedData.executor = { position: actor?.role, name: actor?.fio ?? '' };
    }

    return returnedData;
  }

  private getNodeTitle(): string {
    switch (this.action.type) {
      case 'upload':
      case 'generate_document_from_template':
        return 'Загрузка';
      case 'ukep_sign':
      case 'ukep_sign_batch':
      case 'unep_sign':
      case 'unep_sign_batch':
      case 'pep_sign':
      case 'pep_sign_batch':
        return 'Подписание';
      case 'accept':
        return 'Проверка';
      case 'system':
        return 'Обработка';
      case 'decline':
        return 'Отказ';
      case 'system_set_vacation_days':
        return 'Заполнение';
      case 'system_sync_scheduled_vacations':
        return 'Согласование';
      case 'competency_eval':
        return 'Заполнение';
      case 'competency_profile':
        return 'Заполнение';
      default:
        return '';
    }
  }

  private getTooltipTitle({ name, status, actions }: EventNodeFlow): string {
    switch (status) {
      case 'finished': {
        if (actions.find(({ type }) => type === 'decline_sign')) {
          return 'Отказ от подписания';
        } else {
          switch (this.action.type) {
            case 'upload':
            case 'generate_document_from_template':
              return 'Загружено';
            case 'ukep_sign':
            case 'ukep_sign_batch':
            case 'unep_sign':
            case 'unep_sign_batch':
            case 'pep_sign':
            case 'pep_sign_batch':
              return 'Подписано';
            case 'accept':
              return 'Проверено';
            case 'system':
              return 'Обработано';
            case 'decline':
            case 'decline_sign':
              return 'Отказано';
            case 'competency_eval':
              return 'Заполнение';
            case 'competency_profile':
              return 'Заполнение';
            case 'system_set_vacation_days':
              return 'Заполнение';
            case 'system_sync_scheduled_vacations':
              return 'Согласование';
            default:
              return 'Завершено';
          }
        }
      }
      case 'active':
      case 'inactive':
        return this.getNodeTitle();
      case 'canceled':
        return 'Отменено';
      case 'skipped':
        switch (this.action.type) {
          case 'upload':
          case 'generate_document_from_template':
            return 'Не загружено';
          case 'ukep_sign':
          case 'ukep_sign_batch':
          case 'unep_sign':
          case 'unep_sign_batch':
          case 'pep_sign':
          case 'pep_sign_batch':
            return 'Не подписано';
          case 'accept':
            return 'Не проверено';
          case 'system':
            return 'Не обработано';
          case 'decline':
          case 'decline_sign':
            return 'Не отказано';
          case 'system_set_vacation_days':
            return 'Заполнение';
          case 'system_sync_scheduled_vacations':
            return 'Согласование';
          case 'competency_eval':
            return 'Заполнение';
          case 'competency_profile':
            return 'Заполнение';
          default:
            return 'Пропущено';
        }
      default:
        return name;
    }
  }

  private getTooltip(
    node: EventNodeFlow,
    inPaper?: boolean,
  ): FlowTreeNodeTooltip {
    const {
      activity_date: date,
      actor,
      cancel_reason: cancelReason,
      actions,
      documents,
      status,
      name,
    } = node;

    if (this.inPaper && inPaper) {
      return {
        title: 'В бумаге',
        comment: 'Документы по заявке нужно подписать на бумаге, как раньше',
        date: this.dateFormat(date),
      };
    }

    const tooltipTitle = TRIVIO_ACTION_TYPE.includes(this.action.type)
      ? name
      : this.getTooltipTitle(node);

    const tooltip: FlowTreeNodeTooltip = {
      title: tooltipTitle,
      date: this.dateFormat(date),
      info: this.getTooltipInfo(actions, documents),
    };

    if (status === 'canceled' || status === 'skipped') {
      tooltip.comment = cancelReason
        ? this.getTooltipComment(cancelReason, date)
        : null;
      tooltip.executors = this.getExecutorsData();

      if (!cancelReason) {
        tooltip.info = ['Заявка перешла на следующий этап автоматически'];
      }
      if (
        (!cancelReason ||
          ['manual_employee', 'manual_company', 'undefined'].includes(
            cancelReason,
          )) &&
        actor
      ) {
        tooltip.executor = this.getExecutor(actor);
      }
    } else if (!date) {
      tooltip.executors = this.getExecutorsData();
    } else if (status === 'active') {
      tooltip.executors = this.getExecutorsData();
    } else if (actor) {
      tooltip.executor = this.getExecutor(actor);
    }

    return tooltip;
  }

  private getExecutor({ role, fio }: EventNodeFlowActor): Executor {
    const executor: Executor = { position: role ?? '', name: fio ?? '' };

    return executor;
  }

  private getExecutorsData(): { position: string; names: string[] }[] {
    const executorsObj = this.action.responsible.reduce((acc, item) => {
      acc[item.role] = {
        names: acc[item.role]?.names
          ? [...acc[item.role]?.names, item.name]
          : [item.name],
        position: item.role,
      };

      return acc;
    }, {} as Record<string, { names: string[]; position: string }>);

    const executorsData = Object.values(executorsObj);

    return executorsData;
  }

  private dateFormat(date?: string, dateOnly = false): string {
    return date
      ? format(parseISO(date), dateOnly ? 'dd.MM.yyyy' : 'dd.MM.yyyy HH:mm')
      : '';
  }

  private getConstrictionNode(transitions: Transition[]): FlowTreeNode {
    let constrictionChild: FlowTreeNode | null = null;
    const constrictOf = [];

    for (const { to_node_id: toNodeId } of transitions) {
      let rawNode = this.nodes.find(
        ({ node_id: nodeId }) => nodeId === toNodeId,
      );
      let parentNode = rawNode ? this.rawNodeToResultNode(rawNode) : null;

      if (parentNode) {
        constrictOf.push(parentNode);

        loop: while (rawNode) {
          const { transitions: nodeTransitions = [] } = rawNode;
          const children = [];

          for (const { to_node_id: id } of nodeTransitions) {
            rawNode = this.nodes.find(({ node_id: nodeId }) => nodeId === id);

            if (this.constrictions.has(id)) {
              if (constrictionChild) {
                if (constrictionChild.id !== id) {
                  throw new AppError('client', {
                    code: 400,
                    message: 'Bad Request',
                    error: 'Bad Request',
                  });
                }
              } else if (rawNode) {
                constrictionChild = this.getNode(rawNode.node_id);
                if (!constrictionChild) {
                  throw new AppError('client', {
                    code: 400,
                    message: 'Bad Request',
                    error: 'Bad Request',
                  });
                }
              }
              break loop;
            } else if (rawNode) {
              const node = this.rawNodeToResultNode(rawNode);
              children.push(node);
              parentNode.children = children;
              parentNode = node;
            }
          }
        }
      }
    }

    const constrictionNode: FlowTreeNode = {
      id: `constriction-to-${constrictionChild?.id ?? 'none'}`,
      constrictOf,
      state: constrictionChild?.state ?? 'feature',
      enabled: true,
      title: 'constriction',
    };

    if (constrictionChild) {
      constrictionNode.children = [constrictionChild];
    }

    return constrictionNode;
  }

  private getNode(id: string, startNode = false): FlowTreeNode | null {
    const rawNode = this.nodes.find(({ node_id: nodeId }) => nodeId === id);

    if (!rawNode) {
      return null;
    }

    const resultNode = this.rawNodeToResultNode(rawNode, startNode);

    if (resultNode.state !== 'paper') {
      const { actions, transitions } = rawNode;

      if (actions.length === 1 && transitions.length > 1) {
        const constriction = this.getConstrictionNode(transitions);
        resultNode.children = [constriction];
      } else {
        const children = transitions?.reduce(
          (res: FlowTreeNode[], { to_node_id: toNodeId }) => {
            const child = this.getNode(toNodeId);
            if (child) {
              res.push(child);
            }
            return res;
          },
          [],
        );

        if (children.length) {
          resultNode.children = children;
        } else if (this.inPaper) {
          resultNode.children = [this.getPaperNode(rawNode)];
        }
      }
    }

    return resultNode;
  }

  private getPaperNode(node: EventNodeFlow): FlowTreeNode {
    return {
      id: `paper_node_${node.node_id}_${this.paperNodeId++}`,
      title: 'В бумаге',
      enabled: true,
      state: 'paper',
      tooltip: this.getTooltip(node, true),
    };
  }

  private toStartNode({
    actor,
    node_id: id,
    status,
  }: EventNodeFlow): FlowTreeNode {
    return {
      id,
      title: 'Старт',
      state: this.mapStatus(status),
      enabled: status !== 'inactive',
      executor: { position: actor?.role ?? '' },
    };
  }

  private toFinalNode(node: EventNodeFlow): FlowTreeNode {
    const { node_id: id, status, name } = node;

    const processStatus = name === 'Отказ от подписания' ? 'canceled' : status;

    return {
      id,
      title: name || 'Завершено',
      state: this.mapStatus(processStatus),
      enabled: status !== 'inactive',
      tooltip: this.getTooltip(node),
    };
  }

  public init(nodes: EventNodeFlow[], inPaper: boolean) {
    this.nodes = nodes.map(({ actions, transitions, ...node }) => ({
      ...node,
      actions: actions.filter(({ type }) => !REJECT_ACTION_TYPE.includes(type)),
      transitions: transitions.filter(
        ({ action_type: type }) => !REJECT_ACTION_TYPE.includes(type),
      ),
    }));

    this.inPaper = inPaper;
    this.paperNodeId = 0;

    const found = new Set<string>();
    this.constrictions = new Set<string>();

    for (const { transitions } of nodes) {
      for (const { to_node_id: toNodeId } of transitions) {
        if (found.has(toNodeId)) {
          this.constrictions.add(toNodeId);
        } else {
          found.add(toNodeId);
        }
      }
    }
  }

  public getFlowTree(id: string): FlowTreeNode | null {
    return this.getNode(id, true);
  }
}

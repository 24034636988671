import {
  createAsyncThunk,
  ActionReducerMapBuilder,
  createAction,
} from '@reduxjs/toolkit';

import { AuthRouterService } from '../../../services';
import { AuthState, clearState, ThunkExtra } from '../../auth.state';
import { AuthCandidateIdService } from '../../../services/candidate/auth.candidate.id.service';

export const clearAuthState = createAction<void>('auth/clearState');

export const redirectToHome = createAsyncThunk<void, void, ThunkExtra>(
  'auth/redirectToHome',
  (_, { dispatch, extra: { inject } }) => {
    dispatch(clearAuthState());
    inject(AuthRouterService).redirectToHome();
  },
);

export const redirectToLogin = createAsyncThunk<void, void, ThunkExtra>(
  'auth/redirectToLogin',
  (_, { dispatch, extra: { inject } }) => {
    dispatch(clearAuthState());
    inject(AuthRouterService).redirectToLogin();
  },
);

export const redirectToCandidateLogin = createAsyncThunk<
  void,
  string,
  ThunkExtra
>('auth/redirectToCandidateLogin', (id, { dispatch, extra: { inject } }) => {
  dispatch(clearAuthState());
  inject(AuthCandidateIdService).save(id);
  inject(AuthRouterService).redirectToCandidateLogin();
});

export const redirectToRegister = createAsyncThunk<void, void, ThunkExtra>(
  'auth/redirectToRegister',
  (_, { dispatch, extra: { inject } }) => {
    dispatch(clearAuthState());
    inject(AuthRouterService).redirectToRegister();
  },
);

export const redirectToPhoneChange = createAsyncThunk<void, void, ThunkExtra>(
  'auth/redirectToPhoneChange',
  (_, { dispatch, extra: { inject } }) => {
    dispatch(clearAuthState());
    inject(AuthRouterService).redirectToPhoneChange();
  },
);

export const redirectToPasswordChange = createAsyncThunk<
  void,
  string | undefined,
  ThunkExtra
>(
  'auth/redirectToPasswordChange',
  (authInstanceId, { dispatch, extra: { inject } }) => {
    dispatch(clearAuthState());
    inject(AuthRouterService).redirectToPasswordChange(authInstanceId);
  },
);

export const redirectToSendInvite = createAsyncThunk<void, void, ThunkExtra>(
  'auth/redirectToSendInvite',
  (_, { dispatch, extra: { inject } }) => {
    dispatch(clearAuthState());
    inject(AuthRouterService).redirectToSendInvite();
  },
);

export const redirectToSendInviteForPhone = createAsyncThunk<
  void,
  void,
  ThunkExtra
>('auth/redirectToSendInviteForPhone', (_, { dispatch, extra: { inject } }) => {
  dispatch(clearAuthState());
  inject(AuthRouterService).redirectToSendInviteForPhone();
});

export const redirectToSaved = createAsyncThunk<void, void, ThunkExtra>(
  'auth/redirectToSaved',
  (_, { dispatch, extra: { inject } }) => {
    dispatch(clearAuthState());
    inject(AuthRouterService).redirectToSaved();
  },
);

export const redirectToQr = createAsyncThunk<void, void, ThunkExtra>(
  'auth/redirectToPhoneChange',
  (_, { dispatch, extra: { inject } }) => {
    dispatch(clearAuthState());
    inject(AuthRouterService).redirectToQr();
  },
);

export const routerReducers = (builder: ActionReducerMapBuilder<AuthState>) => {
  builder.addCase(clearAuthState, (state) => {
    clearState(state);
  });
};

import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(({ breakpoints, tokens, px }) => ({
  root: {
    gap: tokens.spacing['8'],
    marginBottom: tokens.spacing['24'],

    [breakpoints.up('md')]: {
      gap: tokens.spacing['24'],
    },
  },

  itemRoot: {
    alignItems: 'flex-start',
    margin: 0,

    '& $inputRoot .MuiIconButton-label': {
      position: 'relative',

      '&::after': {
        position: 'absolute',
        top: tokens.spacing['4'],
        left: tokens.spacing['4'],
        display: 'block',
        width: px(16),
        height: px(16),
        backgroundColor: tokens.colors.bg.light.primary,
        borderRadius: '50%',
        content: "''",
      },
    },

    '& .MuiSvgIcon-root': {
      zIndex: 1,
    },

    '&:hover:not($disabled)': {
      '& .MuiSvgIcon-root path': {
        fill: tokens.colors.original.brand.primary,
      },

      '& $checked .MuiSvgIcon-root path': {
        fill: tokens.colors.original.brand.tertirary,
      },
    },

    '&:not($disabled) $inputRoot$checked .MuiSvgIcon-root path': {
      fill: tokens.colors.original.brand.primary,
    },

    '&$disabled $inputRoot .MuiIconButton-label::after': {
      top: tokens.spacing['2'],
      left: tokens.spacing['2'],
      width: px(20),
      height: px(20),
      backgroundColor: tokens.colors.bg.greyscale.primary,
    },

    '&$disabled $inputRoot$checked .MuiIconButton-label .MuiSvgIcon-root': {
      '&:first-child path': {
        fill: 'transparent',
      },

      '&:last-child path': {
        fill: tokens.colors.stroke.secondary,
      },
    },
  },

  inputRoot: {
    paddingTop: 0,
    paddingRight: tokens.spacing['2'],
    paddingBottom: 0,
    paddingLeft: tokens.spacing['2'],

    '&:hover, &:hover$checked': {
      backgroundColor: 'transparent',
    },

    '& .MuiSvgIcon-root path': {
      fill: tokens.colors.stroke.primary,
    },
  },

  label: {
    marginTop: tokens.spacing['2'],
  },

  checked: {},

  disabled: {},
}));

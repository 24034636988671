import { IsNotEmpty, IsUUID } from 'class-validator';

import { FormAttributesRequest } from '../../types';

const notEmptyOptions = {
  message: 'Обязательное поле',
};

export class SignPepDto {
  @IsNotEmpty({
    ...notEmptyOptions,
    groups: ['form'],
  })
  @IsUUID(undefined, {
    groups: ['form'],
  })
  eventId: string;

  @IsNotEmpty({
    ...notEmptyOptions,
    groups: ['form'],
  })
  @IsUUID(undefined, {
    groups: ['form'],
  })
  nodeId: string;

  attributes?: FormAttributesRequest;
}

/* eslint-disable react-hooks/exhaustive-deps */
import React, { createRef, useCallback, useEffect, useMemo } from 'react';

import { useLocation } from 'react-router-dom';

import { Box } from '@vk-hr-tek/ui/Box';
import { Grid } from '@vk-hr-tek/ui/Grid';

import { Page } from '../../../layout';
import { useDispatch, useSelector } from '../../../app/hooks';
import {
  selectUser,
  selectError,
  selectStatus,
  profileView,
  getSubstitutes,
} from '../../slice';

import { TokenGeneration } from './TokenGeneration';
import { UserInfo } from './UserInfo';
import { Notifications } from './Notifications';
import { MobileApp } from './MobileApp';
import { Roles } from './Roles';
import { SubstitutesList } from './Substitutes';
import { CompanyUnitsList } from './CompanyUnits';
import { GeneralInfo } from './GeneralInfo';

enum ProfileSectionsEnum {
  SUBSTITUTES = 'substitutes',
}

export const Profile = () => {
  const user = useSelector(selectUser);
  const status = useSelector(selectStatus);
  const error = useSelector(selectError);
  const dispatch = useDispatch();
  const { hash } = useLocation();

  const activeProfileSection = hash.substring(1);

  const refs = useMemo(
    () =>
      Object.values(ProfileSectionsEnum).reduce((acc, profileSection) => {
        acc[profileSection] = createRef();

        return acc;
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      }, {} as Record<string, any>),
    [],
  );

  const handleScrollToProfileSection = useCallback(
    (profileSection: string) =>
      refs[profileSection]?.current?.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      }),
    [refs],
  );

  useEffect(() => {
    dispatch(profileView());
  }, [dispatch]);

  useEffect(() => {
    dispatch(getSubstitutes());
  }, [dispatch]);

  useEffect(() => {
    if (activeProfileSection) {
      handleScrollToProfileSection(activeProfileSection);
    }
  }, [handleScrollToProfileSection, activeProfileSection]);

  return (
    <Page error={error} title="Профиль" showTitleMobile status={status}>
      <Box overflow="hidden">
        {user && (
          <Grid container spacing="24">
            <Grid item xs={12}>
              <UserInfo />
            </Grid>

            <Grid item md={8} xs={12}>
              <Box display="flex" flexDirection="column" gap="24">
                <GeneralInfo />

                {user?.groups?.length > 0 && <Roles />}

                <SubstitutesList />

                <CompanyUnitsList />
              </Box>
            </Grid>

            <Grid item md={4} xs={12}>
              <Box gap="24" display="flex" flexDirection="column">
                {window.REACT_APP_VKHRTEK_MOBILE_APP_VISIBLE && <MobileApp />}

                <Notifications />

                {user?.groups?.length > 0 && <TokenGeneration />}
              </Box>
            </Grid>
          </Grid>
        )}
      </Box>
    </Page>
  );
};

import { injectable } from 'inversify';

import {
  CreateEventEmployee,
  CreateEventCompanyItem,
  AssignableRole,
} from '@app/gen/events';

@injectable()
export class AbsenceEventCreationMapper {
  findFormRoleFields(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    formValuesObj: Record<string, any>,
    getFullFieldName?: boolean,
  ) {
    return Object.keys(formValuesObj)
      .filter((roleId) => roleId)
      .map((key) => {
        const regexpResultArray = key.match(/^roles_([0-9a-zA-Z-]+)$/);

        if (regexpResultArray) {
          return getFullFieldName ? regexpResultArray[0] : regexpResultArray[1];
        }

        return null;
      });
  }

  gatherAssignedRoles(formValuesObj: Record<string, any>) {
    return this.findFormRoleFields(formValuesObj).reduce(
      (acc: { role_id: string; group_id: string }[], roleId) => {
        return !roleId
          ? acc
          : [
              ...acc,
              { role_id: roleId, group_id: formValuesObj[`roles_${roleId}`] },
            ];
      },
      [],
    );
  }

  processCreateEventItem(items: CreateEventCompanyItem[]) {
    return items.map((item) => ({
      companyId: item.company_id,
      companyName: item.company_name,
    }));
  }

  processEmployees(employees: CreateEventEmployee[]) {
    return employees
      .map((employee) => ({
        ...employee,
        personnelNumber: employee.personnel_number,
        eventTypes: [...employee.event_types].sort((a, b) =>
          (a.name || '').toLowerCase() > (b.name || '').toLowerCase() ? 1 : -1,
        ),
      }))
      .sort((a, b) =>
        (a.name || '').toLowerCase() > (b.name || '').toLowerCase() ? 1 : -1,
      );
  }

  processAssignableRoles(
    roles: AssignableRole[] | undefined,
    id: string,
    required: boolean,
  ) {
    return (
      roles?.reduce((acc, role) => {
        return {
          ...acc,
          [role.role.id]: {
            roleName: role.role.name,
            defaultValue: role.default_group?.group_id,
            defaultName: role.default_group?.name,
            position: role.default_group?.position,
            personalNumber: role.default_group?.personnel_number,
            companyName: role.default_group?.company?.name,
            scope: role.scope,
            eventTypeId: id,
            required,
          },
        };
      }, {}) || null
    );
  }
}

import { Type } from 'class-transformer';
import { IsNotEmpty, IsArray, ValidateNested } from 'class-validator';

import { UpdateManagerSettings } from './update-manager-settings.dto';

export class UpdateApprovalSettingsDto {
  @ValidateNested({ each: true })
  @Type(() => UpdateManagerSettings)
  @IsArray({
    message: 'Вы указали неверные настройки согласования',
  })
  @IsNotEmpty({
    message: 'Вы забыли указать настройки согласования',
  })
  managerSettings: UpdateManagerSettings[];
}

import { injectable } from 'inversify';

import {
  CreateEventOption,
  CreateEventEmployee,
  AssignableRole,
  CreateEventCompanyItem,
} from '@app/gen/events';

@injectable()
export class EventsCreateMapper {
  processCreateEventOption(options: CreateEventOption[]) {
    return options.map((option) => ({
      optionName: option.option_name,
      employeeId: option.employee_id,
      eventTypeId: option.event_type_id,
      eventTypeName: option.event_type_name,
      employeeName: option.employee_name,
      personnelNumber: option.personnel_number,
    }));
  }

  processCreateEventItem(items: CreateEventCompanyItem[]) {
    return items.map((item) => ({
      companyId: item.company_id,
      companyName: item.company_name,
    }));
  }

  processEmployees(employees: CreateEventEmployee[]) {
    return employees
      .map((employee) => ({
        ...employee,
        personnelNumber: employee.personnel_number,
        eventTypes: [...employee.event_types].sort((a, b) =>
          (a.name || '').toLowerCase() > (b.name || '').toLowerCase() ? 1 : -1,
        ),
      }))
      .sort((a, b) =>
        (a.name || '').toLowerCase() > (b.name || '').toLowerCase() ? 1 : -1,
      );
  }

  processCreateEventOptionCompany(options: { company_id: string }[]) {
    return options.map((option) => ({
      companyId: option.company_id,
    }));
  }

  processAssignableRoles(
    roles: AssignableRole[] | undefined,
    id: string,
    required: boolean,
  ) {
    return (
      roles?.reduce((acc, role) => {
        return {
          ...acc,
          [role.role.id]: {
            roleName: role.role.name,
            defaultValue: role.default_group?.group_id,
            defaultName: role.default_group?.name,
            position: role.default_group?.position,
            personalNumber: role.default_group?.personnel_number,
            companyName: role.default_group?.company?.name,
            scope: role.scope,
            eventTypeId: id,
            required,
          },
        };
      }, {}) || null
    );
  }
}

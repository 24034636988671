import { IsNotEmpty, IsUUID, IsNumber } from 'class-validator';

export class RecognizeQrCodeDto {
  @IsNotEmpty()
  @IsNumber()
  cache_file_id: string;

  @IsNotEmpty()
  @IsUUID()
  event_id: string;

  @IsNotEmpty()
  @IsUUID()
  node_id: string;

  @IsNotEmpty()
  @IsUUID()
  attribute_id: string;
}

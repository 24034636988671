import React, { useState, useEffect, ReactNode, useCallback } from 'react';

import { formatISO } from 'date-fns';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import {
  Calendar as MUICalendar,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import TextField, { TextFieldProps } from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import Popover from '@material-ui/core/Popover';
import classNames from 'classnames';

import { Calendar } from '@vk-hr-tek/core/calendar';
import { FormatService } from '@vk-hr-tek/core/format';
import { useInject } from '@vk-hr-tek/core/ioc';

import { Box } from '../../Box';
import { CalendarIcon, CancelIcon } from '../../icons';
import { Label, Preloader } from '../common';

import { Day } from './Day';
import { useStyles } from './DateInput.styles';

interface DateInputProps {
  label: string;
  name?: string;
  onChange: (date: string | undefined) => void;
  onClear?: () => void;
  onBlur?: () => void;
  onLogAction?: (type: string) => void;
  placeholder?: string;
  shouldDisableDate?: (date: Date | MaterialUiPickersDate) => boolean;
  required?: boolean;
  disablePast?: boolean;
  disabled?: boolean;
  minDate?: Date;
  maxDate?: Date;
  recognizedValue?: string;
  isRecognitionUsedBefore?: string;
  externalError?: string;
  otherSelectedDate?: string;
  value?: string;
  clearable?: boolean;
  alwaysShowClear?: boolean;
  error?: string;
  showRange?: boolean;
  loading?: boolean;
  tooltip?: ReactNode | null;
  id?: string;
  testId?: string;
  formBlur?: (name: string) => void;
}

export const DateInput = ({
  label,
  name,
  onChange,
  formBlur,
  onClear,
  onLogAction,
  placeholder = 'ДД.ММ.ГГГГ',
  required = false,
  disablePast = false,
  disabled = false,
  minDate = Calendar.minDate,
  maxDate = Calendar.maxDate,
  recognizedValue,
  isRecognitionUsedBefore,
  shouldDisableDate,
  externalError,
  otherSelectedDate,
  clearable = false,
  alwaysShowClear = false,
  error,
  value,
  onBlur,
  loading = false,
  showRange = false,
  tooltip = null,
  testId = 'test-id-date-input-text-field',
  id,
  ...rest
}: DateInputProps) => {
  const classes = useStyles();
  const calendar = useInject(Calendar);
  const format = useInject(FormatService);

  const [dateValue, setDateValue] = useState<Date | null | string>(
    value || null,
  );

  const [errorText, setErrorText] = useState(error);
  const [loaded, setLoaded] = useState(false);
  const [showClear, setShowClear] = useState(false);
  const [closestEnabledDate, setClosestEnabledDate] = useState(new Date());
  const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null);
  const [hoverDate, setHoverDate] = useState<Date | null>(null);

  const open = Boolean(anchorEl);

  const handleDateChange = useCallback(
    (date?: MaterialUiPickersDate) => {
      setLoaded(false);
      if (errorText !== error) {
        setErrorText(undefined);
      }
      setDateValue(String(date));

      if (!date) {
        onChange(undefined);
        formBlur?.(name as string);
        setLoaded(true);
        return;
      }

      if (calendar.isValidDate(date)) {
        if (shouldDisableDate?.(date)) {
          setLoaded(true);
          setErrorText('Некорректная дата');
          onChange(undefined);
          formBlur?.(name as string);
          return;
        }
        if (
          new Date(minDate) <= new Date(date) &&
          new Date(date) <= new Date(maxDate)
        ) {
          const newValueIso = formatISO(new Date(date), {
            representation: 'date',
          });
          setLoaded(true);
          onLogAction?.('Введена дата');
          onChange(newValueIso);
          formBlur?.(name as string);
        } else {
          onChange(undefined);
          formBlur?.(name as string);
        }
      }
      setLoaded(true);
    },
    [
      calendar,
      maxDate,
      minDate,
      onChange,
      formBlur,
      name,
      onLogAction,
      shouldDisableDate,
      error,
      errorText,
    ],
  );

  useEffect(() => {
    setDateValue(value ? new Date(value) : null);
  }, [value]);

  useEffect(() => {
    setErrorText(error);
  }, [error]);

  useEffect(() => {
    if (recognizedValue && !value) {
      onChange(recognizedValue);

      formBlur?.(name as string);
    }
  }, [onChange, name, formBlur, recognizedValue, value]);

  useEffect(() => {
    if (onBlur && isRecognitionUsedBefore && !disabled && !recognizedValue) {
      onBlur();
    }
  }, [isRecognitionUsedBefore, disabled, onBlur]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    calendar.download(new Date()).then(() => setLoaded(true));
  }, [calendar]);

  const clearDateInput = useCallback(
    (e: React.MouseEvent<HTMLDivElement>) => {
      e.preventDefault();
      setLoaded(false);
      setShowClear(false);
      setAnchorEl(null);
      onChange(undefined);
      formBlur?.(name as string);
      onBlur?.();
      onClear?.();
      setTimeout(() => setLoaded(true), 500);
    },
    [onChange, name, formBlur, onBlur, onClear],
  );

  const dateIconClickHandler = useCallback(
    (e: React.MouseEvent<HTMLDivElement>) => {
      e.stopPropagation();
      if (loaded && !disabled) {
        onLogAction?.('Открыть календарь');
        setAnchorEl(e.currentTarget);
      }
    },
    [disabled, loaded, onLogAction],
  );

  const isLoading = !loaded || loading;

  const getCalendarIconColor = useCallback(
    (isDisabled?: boolean, isError?: boolean) => {
      if (isDisabled) {
        return 'disabled';
      }
      if (isError) {
        return 'error';
      }

      return 'primary';
    },
    [],
  );

  const renderEndAdornment = useCallback(
    (props: TextFieldProps) => {
      if (isLoading) {
        return <Preloader />;
      }

      return (
        <Box display="flex">
          {clearable && props.value && !props.disabled && (
            <Box
              display="flex"
              alignItems="center"
              className={classes.clearIcon}
              mr="12"
              height={24}
              onClick={clearDateInput}
            >
              <CancelIcon color={props.error ? 'error' : 'disabled'} />
            </Box>
          )}
          <Box
            onClick={(e: React.MouseEvent<HTMLDivElement>) => {
              e.stopPropagation();
              if (loaded && !props.disabled) {
                setAnchorEl(e.currentTarget);
              }
            }}
          >
            <Box
              onClick={dateIconClickHandler}
              display="flex"
              alignItems="center"
            >
              <CalendarIcon
                color={getCalendarIconColor(props.disabled, !!props.error)}
              />
            </Box>
          </Box>
        </Box>
      );
    },
    [
      classes.clearIcon,
      clearDateInput,
      clearable,
      dateIconClickHandler,
      getCalendarIconColor,
      isLoading,
      loaded,
    ],
  );

  const handleInputBlur = useCallback(() => {
    if (calendar.isValidDate(dateValue) && !errorText) {
      const newValueIso = dateValue
        ? formatISO(new Date(dateValue), {
            representation: 'date',
          })
        : undefined;
      onChange(newValueIso);

      formBlur?.(name as string);
      return;
    }
    onChange(undefined);
    formBlur?.(name as string);
    setErrorText(error);
    setDateValue(value || null);
  }, [calendar, name, formBlur, dateValue, errorText, error, value, onChange]);

  const renderTextField = useCallback(
    (props: TextFieldProps) => {
      return (
        <Box>
          <TextField
            value={isLoading ? 'Загрузка...' : props.value}
            data-testid={testId}
            classes={{ root: classes.textDateInput }}
            type="text"
            variant="outlined"
            fullWidth
            placeholder={placeholder}
            error={props.error}
            disabled={isLoading || props.disabled}
            InputProps={{
              id,
              className: 'textFieldInput aqa_dt_input',
              classes: { adornedEnd: classes.adornedEnd },
              endAdornment: renderEndAdornment(props),
            }}
            inputProps={{
              name,
            }}
            onChange={props.onChange}
            onBlur={props?.onBlur}
          />
          {externalError ? (
            <FormHelperText error>{externalError}</FormHelperText>
          ) : (
            props.error && (
              <FormHelperText error>{props.helperText}</FormHelperText>
            )
          )}
        </Box>
      );
    },
    [
      classes.adornedEnd,
      classes.textDateInput,
      externalError,
      id,
      isLoading,
      placeholder,
      renderEndAdornment,
      name,
      testId,
    ],
  );

  return (
    <FormControl className={classNames(classes.input, 'aqa_date_input')}>
      <Label label={label} required={required} tooltip={tooltip} />
      <KeyboardDatePicker
        variant="inline"
        format="dd.MM.yyyy"
        value={dateValue}
        onChange={handleDateChange}
        required={required}
        invalidDateMessage="Некорректная дата"
        minDateMessage={`Дата должна быть больше чем ${format.toDate(
          minDate.toString(),
        )}`}
        maxDateMessage={`Дата должна быть меньше чем ${format.toDate(
          maxDate.toString(),
        )}`}
        TextFieldComponent={renderTextField}
        onBlur={handleInputBlur}
        {...(errorText && {
          error: !!errorText,
          helperText: errorText,
        })}
        disabled={disabled}
      />
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={() => {
          setAnchorEl(null);
          if (onBlur) {
            onBlur();
          }
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <Box className="aqa_calendar" pb="16">
          <MUICalendar
            {...rest}
            date={value ? new Date(value) : closestEnabledDate}
            onMonthChange={(date) => calendar.download(date || undefined)}
            disablePast={disablePast}
            minDate={minDate}
            maxDate={maxDate}
            onChange={(date, isFinal) => {
              if (isFinal) {
                onLogAction?.('Выбрана дата');
                onChange(
                  date
                    ? formatISO(date, { representation: 'date' })
                    : undefined,
                );
                setAnchorEl(null);
                if (onBlur) {
                  onBlur();
                }
                setTimeout(() => setShowClear(true), 200);
              } else if (date) {
                setClosestEnabledDate(date);
              }
            }}
            {...(shouldDisableDate ? { shouldDisableDate } : {})}
            renderDay={(day, selectedDate, dayInCurrentMonth, dayComponent) => {
              const onMouseEnter = () => {
                setHoverDate(day);
              };

              const onMouseLeave = () => {
                if (day && hoverDate && calendar.isSameDay(day, hoverDate)) {
                  setHoverDate(null);
                }
              };

              let isInRange = false;

              if (hoverDate) {
                isInRange =
                  showRange &&
                  !!day &&
                  !!otherSelectedDate &&
                  calendar.startOfDay(new Date(otherSelectedDate)) <= day &&
                  calendar.startOfDay(new Date(hoverDate)) >= day;
              } else if (value) {
                isInRange =
                  showRange &&
                  !!day &&
                  !!otherSelectedDate &&
                  calendar.startOfDay(new Date(otherSelectedDate)) <= day &&
                  calendar.startOfDay(new Date(value)) >= day;
              }

              const isStart =
                isInRange &&
                !!otherSelectedDate &&
                !!day &&
                calendar.isSameDay(day, new Date(otherSelectedDate));

              if (!day) {
                return (
                  <Day
                    onMouseEnter={onMouseEnter}
                    onMouseLeave={onMouseLeave}
                    isInRange={isInRange}
                    {...dayComponent.props}
                  >
                    {dayComponent.props.children}
                  </Day>
                );
              }

              if (
                (value && calendar.isSameDay(day, new Date(value))) ||
                (otherSelectedDate &&
                  calendar.isSameDay(day, new Date(otherSelectedDate)))
              ) {
                return (
                  <Day
                    onMouseEnter={onMouseEnter}
                    onMouseLeave={onMouseLeave}
                    isInRange={isInRange}
                    isStart={isStart}
                    isEnd={
                      isInRange &&
                      value &&
                      !hoverDate &&
                      calendar.isSameDay(day, new Date(value))
                    }
                    {...dayComponent.props}
                    selected
                    current={false}
                  >
                    {dayComponent.props.children}
                  </Day>
                );
              }

              if (calendar.isSameDay(day, new Date())) {
                return (
                  <Day
                    onMouseEnter={onMouseEnter}
                    onMouseLeave={onMouseLeave}
                    isInRange={isInRange}
                    isStart={isStart}
                    {...dayComponent.props}
                    selected={false}
                    isEnd={
                      isInRange &&
                      hoverDate &&
                      calendar.isSameDay(day, hoverDate)
                    }
                    current
                  >
                    {dayComponent.props.children}
                  </Day>
                );
              }

              if (selectedDate && calendar.isSameDay(day, selectedDate)) {
                return (
                  <Day
                    {...dayComponent.props}
                    onMouseEnter={onMouseEnter}
                    onMouseLeave={onMouseLeave}
                    isInRange={isInRange}
                    isStart={isStart}
                    selected={false}
                    holiday={calendar.isHolidayOrWeekend(day)}
                    isEnd={
                      isInRange &&
                      hoverDate &&
                      calendar.isSameDay(day, hoverDate)
                    }
                  >
                    {dayComponent.props.children}
                  </Day>
                );
              }

              if (calendar.isHolidayOrWeekend(day)) {
                return (
                  <Day
                    onMouseEnter={onMouseEnter}
                    onMouseLeave={onMouseLeave}
                    isInRange={isInRange}
                    isStart={isStart}
                    isEnd={
                      isInRange &&
                      hoverDate &&
                      calendar.isSameDay(day, hoverDate)
                    }
                    {...dayComponent.props}
                    holiday
                  >
                    {dayComponent.props.children}
                  </Day>
                );
              }

              return (
                <Day
                  onMouseEnter={onMouseEnter}
                  onMouseLeave={onMouseLeave}
                  isInRange={isInRange}
                  isStart={isStart}
                  isEnd={
                    isInRange && hoverDate && calendar.isSameDay(day, hoverDate)
                  }
                  {...dayComponent.props}
                >
                  {dayComponent.props.children}
                </Day>
              );
            }}
          />
          {clearable && value && (alwaysShowClear || showClear) && (
            <Box pt="16" px="16" onClick={clearDateInput}>
              <a href="#" className={classes.action}>
                Сбросить
              </a>
            </Box>
          )}
        </Box>
      </Popover>
    </FormControl>
  );
};

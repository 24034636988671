import { Type } from 'class-transformer';
import { IsArray, IsNotEmpty, ValidateNested } from 'class-validator';

import { PolicyVersion } from './policy-version.dto';

const acceptNotEmptyOptions = {
  message: 'Обязательное поле',
};

export class AcceptDto {
  @IsNotEmpty(acceptNotEmptyOptions)
  @IsArray()
  @ValidateNested({ each: true })
  @Type(() => PolicyVersion)
  policyVersions: PolicyVersion[];
}

import { IsIn, IsOptional, IsString } from 'class-validator';

export class Filters {
  @IsOptional()
  @IsIn(['false', 'true'])
  accepted?: 'false' | 'true' = 'false';

  @IsOptional()
  @IsString()
  employee_query?: string;
}

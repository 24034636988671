import { IsNotEmpty, IsUUID } from 'class-validator';

export const acceptNotEmptyOptions = {
  message: 'Обязательное поле',
};

export class PolicyVersion {
  @IsNotEmpty(acceptNotEmptyOptions)
  policy_version_id: string;

  @IsUUID()
  @IsNotEmpty(acceptNotEmptyOptions)
  employee_id: string;
}

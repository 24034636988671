import React from 'react';

import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';

export const GosUslugiIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props} width="25" height="24" viewBox="0 0 25 24" fill="none">
      <path
        d="M21.488 8.55031C21.4073 7.49385 20.5995 6.13942 19.7917 5.4893C18.8833 4.82396 17.9395 4.20904 16.9645 3.64727C15.9968 3.07025 14.9991 2.54579 13.9757 2.07614C13.429 1.85178 12.8428 1.7412 12.2524 1.75107C11.6619 1.73859 11.0751 1.84927 10.5292 2.07614C9.50592 2.54612 8.50825 3.07058 7.54041 3.64727C5.97871 4.59537 4.71319 5.4893 4.71319 5.4893C4.21608 5.90785 3.80915 6.4163 3.51009 6.98324C3.2792 7.42094 3.63336 7.90008 4.12823 7.90008C4.67793 7.90008 5.16585 7.58013 5.52081 7.16039C5.67256 6.98095 5.84031 6.81381 6.02277 6.66123C6.02277 6.66123 7.06768 5.92822 8.35715 5.15077C9.15628 4.67788 9.98003 4.24783 10.8249 3.86244C11.2757 3.67641 11.7602 3.58565 12.2478 3.59589C12.7352 3.58779 13.2192 3.67847 13.6706 3.86244C14.5157 4.24756 15.3394 4.67762 16.1384 5.15077C16.9435 5.61142 17.7228 6.11566 18.4728 6.66123C19.1397 7.19434 19.8067 8.30497 19.8734 9.17126C19.8734 9.17126 20.029 10.4596 20.0513 11.9856C20.0499 12.928 19.998 13.8697 19.8956 14.8066C19.7405 15.7876 19.2351 16.6792 18.4728 17.3166C18.4728 17.3166 17.4279 18.0719 16.1384 18.8493C15.3393 19.3222 14.5155 19.7523 13.6706 20.1376C13.2199 20.3237 12.7354 20.4144 12.2478 20.4042C11.7608 20.4144 11.2768 20.326 10.8249 20.1443C9.9799 19.7592 9.15613 19.3291 8.35715 18.856C7.06768 18.1007 6.02277 17.3233 6.02277 17.3233C5.8574 17.1911 5.69203 17.0234 5.53547 16.8328C5.1893 16.4112 4.70304 16.1 4.15755 16.1C3.65182 16.1 3.29983 16.5924 3.54467 17.0349C3.86715 17.6177 4.29017 18.1514 4.71319 18.4918C4.71319 18.4918 5.97871 19.4399 7.54041 20.3609C8.50807 20.938 9.50576 21.4624 10.5292 21.9321C11.0765 22.1537 11.6626 22.2615 12.2524 22.249C12.843 22.2615 13.4297 22.1508 13.9757 21.9239C14.999 21.454 15.9966 20.9295 16.9645 20.3528C18.5262 19.4047 19.7917 18.4837 19.7917 18.4837C20.7149 17.7064 21.3271 16.619 21.515 15.4227C21.6389 14.2801 21.7018 13.1318 21.7034 11.9824C21.6765 10.1214 21.488 8.55031 21.488 8.55031Z"
        fill="url(#paint0_linear_147_80)"
      />
      <path
        d="M3.06982 9.74508C3.06982 9.51864 3.25339 9.33508 3.47982 9.33508H9.83483C10.0613 9.33508 10.2448 9.51864 10.2448 9.74508V10.9751C10.2448 11.2015 10.0613 11.3851 9.83483 11.3851H3.06982V9.74508Z"
        fill="#0066B3"
      />
      <path
        d="M3.06982 12.6156H15.9848C16.2113 12.6156 16.3948 12.7992 16.3948 13.0256V14.2556C16.3948 14.482 16.2113 14.6656 15.9848 14.6656H3.47982C3.25339 14.6656 3.06982 14.482 3.06982 14.2556V12.6156Z"
        fill="#EE2F53"
      />
      <defs>
        <linearGradient
          id="paint0_linear_147_80"
          x1="12.4194"
          y1="1.75012"
          x2="12.4194"
          y2="22.25"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#0066B3" />
          <stop offset="0.354167" stopColor="#0066B3" />
          <stop offset="0.6875" stopColor="#EE2F53" />
          <stop offset="1" stopColor="#EE2F53" />
        </linearGradient>
      </defs>
    </SvgIcon>
  );
};

import { IsString, IsIn, IsNotEmpty, IsUUID } from 'class-validator';

export class ManagerSettings {
  @IsUUID(undefined, {
    message: 'Вы указали неверные настройки согласования',
    groups: ['form', 'root', 'submit'],
  })
  @IsNotEmpty({
    message: 'Вы указали неверные настройки согласования',
    groups: ['form', 'root', 'submit'],
  })
  id: string;

  @IsIn(['always', 'direct_only', 'never'], {
    message: 'Вы указали неверные настройки согласования',
    groups: ['form', 'root', 'submit'],
  })
  @IsString({
    message: 'Вы указали неверные настройки согласования',
    groups: ['form', 'root', 'submit'],
  })
  @IsNotEmpty({
    message: 'Вы указали неверные настройки согласования',
    groups: ['form', 'root', 'submit'],
  })
  value: 'always' | 'direct_only' | 'never';
}

import { createAsyncThunk, ActionReducerMapBuilder } from '@reduxjs/toolkit';
import { classToPlain } from 'class-transformer';

import { AppError } from '../../error';
import { ThunkExtra } from '../../utils';
import { TranslationsService } from '../services';
import { LocaleService } from '../../http';

import { TranslationsState } from './translations.state';

export const getTranslations = createAsyncThunk<
  void,
  void,
  ThunkExtra<TranslationsState>
>(
  'core/translations/getTranslations',
  async (_, { rejectWithValue, extra: { inject } }) => {
    try {
      const translationsService = inject(TranslationsService);
      const localeService = inject<LocaleService>(LocaleService);

      const translations = await translationsService.getTranslations();

      await translationsService.addTranslations(
        localeService.get(),
        translations,
      );
    } catch (err) {
      return rejectWithValue(classToPlain(err) as AppError);
    }
  },
);

export const translationsReducers = (
  builder: ActionReducerMapBuilder<TranslationsState>,
) => {
  builder.addCase(getTranslations.pending, (state) => {
    state.loading = 'loading';
  });
  builder.addCase(getTranslations.fulfilled, (state) => {
    state.loading = 'complete';
  });
  builder.addCase(getTranslations.rejected, (state, { payload, error }) => {
    state.loading = 'failed';
    state.error =
      payload ||
      ({
        info: (error && error.message) || 'Unknown error',
        status: 500,
        source: 'client',
        title: 'Internal client error',
      } as AppError);
  });
};

export interface AbsencesRouter {
  goToDetail(id: string): void;
  goToList(query?: string): void;
  goToVacationDetail(query?: string): void;
  goToCompanyAbsencesDetail(query?: string): void;
  goToCreateBatch(params: {
    companyId: string;
    eventTypeId: string;
    employeeId?: string;
    parentEventId?: string;
  }): void;
}

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const AbsencesRouter = Symbol('AbsencesRouter');

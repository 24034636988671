import { injectable } from 'inversify';
import { plainToClass } from 'class-transformer';

import { HttpService } from '@vk-hr-tek/core/http';
import { ValidationService } from '@vk-hr-tek/core/validation';
import { UnmarshallerService } from '@vk-hr-tek/core/unmarshaller';
import { FilterService, FilterType, Filter } from '@vk-hr-tek/core/filter';

import {
  CreateEventBatchDocumentZipResponse,
  EventXlsxResponse,
  EventPdfResponse,
} from '@app/gen/events';

import {
  BatchDownloadStartDto,
  GetEventsPdfDto,
  StartDownloadXlsxDto,
} from '../dto';

@injectable()
export class EventsDownloadService {
  constructor(
    private http: HttpService,
    private unmarshaller: UnmarshallerService,
    private filterService: FilterService,
    private validator: ValidationService,
  ) {}

  async startDownload(batchDownloadStartDto: BatchDownloadStartDto) {
    return this.unmarshaller.unmarshall(
      await this.http.post(
        '/event/batch/document/zip',
        { event_ids: batchDownloadStartDto.event_ids },
        { withSide: true },
      ),
      CreateEventBatchDocumentZipResponse,
    );
  }

  async startDownloadXlsx(
    startDownloadXlsx: StartDownloadXlsxDto,
    availableFilters: Filter[],
    byDocuments?: boolean,
  ) {
    const eventsDto = plainToClass(
      this.filterService.buildDto(StartDownloadXlsxDto, availableFilters),
      startDownloadXlsx,
    );

    await this.validator.validateOrReject(eventsDto);

    const filters: FilterType = {};

    for (const filter in eventsDto.filters) {
      const filterValue = eventsDto.filters[filter];
      this.filterService.sanitizeDates(filterValue);
      filters[filter] = filterValue;
    }

    return this.unmarshaller.unmarshall(
      await this.http.post(
        `/event/xlsx?type=${byDocuments ? 'documents' : 'events'}`,
        { ...(filters ? { filters } : {}) },
        {
          withSide: true,
        },
      ),
      EventXlsxResponse,
    );
  }

  async startDownloadEventsPdf(getEventsPdfDto: GetEventsPdfDto) {
    const filters: FilterType = {};

    for (const filter in getEventsPdfDto.filters) {
      const filterValue = getEventsPdfDto.filters[filter];
      this.filterService.sanitizeDates(filterValue);
      filters[filter] = filterValue;
    }

    const result = await this.unmarshaller.unmarshall(
      await this.http.post(
        `/event/pdf`,
        {
          ...(filters
            ? { filters, event_ids: getEventsPdfDto.event_ids }
            : { event_ids: getEventsPdfDto.event_ids }),
        },
        {
          withSide: true,
        },
      ),
      EventPdfResponse,
    );
    return result;
  }
}

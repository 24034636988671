import { Type, Transform } from 'class-transformer';
import {
  IsInt,
  IsNumber,
  IsIn,
  IsArray,
  IsString,
  IsDateString,
  IsUUID,
  IsNotEmpty,
  ValidateNested,
  IsOptional,
  IsBoolean,
} from 'class-validator';

export class SmevError {
  @IsUUID()
  @IsNotEmpty()
  id: string;

  @IsString()
  @IsNotEmpty()
  code: string;

  @IsIn(['fatal', 'warning'])
  @IsString()
  @IsNotEmpty()
  level: 'fatal' | 'warning';

  @IsString()
  @IsNotEmpty()
  description: string;

  @IsBoolean()
  @IsNotEmpty()
  ignore: boolean;
}

export class SmevValidationErrorsResponse {
  @ValidateNested({ each: true })
  @Type(() => SmevError)
  @IsArray()
  @IsNotEmpty()
  smev_errors: SmevError[];
}

export class ProfileItem {
  @IsUUID()
  @IsNotEmpty()
  id: string;

  @IsString()
  @IsNotEmpty()
  name: string;
}

export class CompanyItem {
  @IsUUID()
  @IsNotEmpty()
  id: string;

  @IsString()
  @IsNotEmpty()
  name: string;

  @IsString()
  @IsOptional()
  tsp_url?: string;

  @IsIn(['api', 'browser_plugin'])
  @IsString()
  @IsOptional()
  hash_source?: 'api' | 'browser_plugin';
}

export class CompanyProfiles {
  @ValidateNested()
  @Type(() => CompanyItem)
  @IsNotEmpty()
  company: CompanyItem;

  @ValidateNested({ each: true })
  @Type(() => ProfileItem)
  @IsArray()
  @IsNotEmpty()
  profiles: ProfileItem[];
}

export class GetProfilesResponse {
  @ValidateNested({ each: true })
  @Type(() => CompanyProfiles)
  @IsArray()
  @IsNotEmpty()
  profiles: CompanyProfiles[];
}

export class CompanyGroup {
  @IsString()
  @IsNotEmpty()
  name: string;
}

export class GetCompanyGroupListResponse {
  @ValidateNested({ each: true })
  @Type(() => CompanyGroup)
  @IsArray()
  @IsNotEmpty()
  items: CompanyGroup[];
}

export class DeadlineSettingAttribute {
  @IsUUID()
  @IsNotEmpty()
  id: string;

  @IsString()
  @IsNotEmpty()
  name: string;
}

export class DeadlineSettingsOptions {
  @ValidateNested({ each: true })
  @Type(() => DeadlineSettingAttribute)
  @IsArray()
  @IsNotEmpty()
  attributes: DeadlineSettingAttribute[];
}

export class GroupItem {
  @IsUUID()
  @IsNotEmpty()
  id: string;

  @IsString()
  @IsNotEmpty()
  name: string;
}

export class GetNodeOptionsResponse {
  @ValidateNested({ each: true })
  @Type(() => GroupItem)
  @IsArray()
  @IsNotEmpty()
  groups: GroupItem[];

  @ValidateNested()
  @Type(() => DeadlineSettingsOptions)
  @IsNotEmpty()
  deadline_settings: DeadlineSettingsOptions;
}

export class GetNewWorkflowParticipantOptionsResponse {
  @ValidateNested({ each: true })
  @Type(() => GroupItem)
  @IsArray()
  @IsNotEmpty()
  groups: GroupItem[];
}

export class MinHiringDayValidatorSettings {
  @IsIn(['min_hiring_day'])
  @IsString()
  @IsNotEmpty()
  type: 'min_hiring_day';

  @IsIn(['eq', 'ge', 'gt', 'le', 'lt'])
  @IsString()
  @IsNotEmpty()
  operation: 'eq' | 'ge' | 'gt' | 'le' | 'lt';
}

export class DivisibleByValidatorSettings {
  @IsIn(['divisible_by'])
  @IsString()
  @IsNotEmpty()
  type: 'divisible_by';

  @IsInt()
  @IsNotEmpty()
  days_count: number;
}

export class MaxDurationValidatorSettings {
  @IsIn(['max_duration'])
  @IsString()
  @IsNotEmpty()
  type: 'max_duration';

  @IsInt()
  @IsNotEmpty()
  max_duration: number;

  @IsBoolean()
  @IsNotEmpty()
  working_days_only: boolean;
}

export class MinDurationValidatorSettings {
  @IsIn(['min_duration'])
  @IsString()
  @IsNotEmpty()
  type: 'min_duration';

  @IsInt()
  @IsNotEmpty()
  min_duration: number;

  @IsBoolean()
  @IsNotEmpty()
  working_days_only: boolean;
}

export class MaxDateValidatorSettings {
  @IsIn(['max_date'])
  @IsString()
  @IsNotEmpty()
  type: 'max_date';

  @IsInt()
  @IsNotEmpty()
  days_count: number;

  @IsBoolean()
  @IsNotEmpty()
  working_days_only: boolean;
}

export class MinDateValidatorSettings {
  @IsIn(['min_date'])
  @IsString()
  @IsNotEmpty()
  type: 'min_date';

  @IsInt()
  @IsNotEmpty()
  days_count: number;

  @IsBoolean()
  @IsNotEmpty()
  working_days_only: boolean;
}

export type ValidatorType = string;

export class Validator {
  @IsUUID()
  @IsNotEmpty()
  id: string;

  @IsIn([
    'min_date',
    'max_date',
    'working_day',
    'weekend_exclusion',
    'min_duration',
    'max_duration',
    'no_overlap',
    'same_month',
    'vacation_available',
    'divisible_by',
    'min_hiring_day',
  ])
  @IsString()
  @IsNotEmpty()
  type:
    | 'min_date'
    | 'max_date'
    | 'working_day'
    | 'weekend_exclusion'
    | 'min_duration'
    | 'max_duration'
    | 'no_overlap'
    | 'same_month'
    | 'vacation_available'
    | 'divisible_by'
    | 'min_hiring_day';

  @IsString()
  @IsNotEmpty()
  description: string;

  @IsString({ each: true })
  @IsArray()
  @IsNotEmpty()
  attributes: string[];

  @IsOptional()
  settings?:
    | MinDateValidatorSettings
    | MaxDateValidatorSettings
    | MinDurationValidatorSettings
    | MaxDurationValidatorSettings
    | DivisibleByValidatorSettings
    | MinHiringDayValidatorSettings;
}

export type ActionType = string;

export class WorkflowTransition {
  @IsUUID()
  @IsNotEmpty()
  from_node: string;

  @IsUUID()
  @IsNotEmpty()
  to_node: string;

  @IsIn([
    'upload',
    'accept',
    'decline',
    'decline_sign',
    'sign',
    'system',
    'generate_document_from_template',
    'return',
    'competency_eval',
    'competency_profile',
    'system_booking_hook',
    'system_booking_approve',
    'system_booking_limits_exceeded_approve',
    'system_booking_trip_create',
    'system_booking_trip_ordering',
    'system_booking_trip_limit',
    'system_booking_trip_limit_approved',
  ])
  @IsString()
  @IsNotEmpty()
  action_type:
    | 'upload'
    | 'accept'
    | 'decline'
    | 'decline_sign'
    | 'sign'
    | 'system'
    | 'generate_document_from_template'
    | 'return'
    | 'competency_eval'
    | 'competency_profile'
    | 'system_booking_hook'
    | 'system_booking_approve'
    | 'system_booking_limits_exceeded_approve'
    | 'system_booking_trip_create'
    | 'system_booking_trip_ordering'
    | 'system_booking_trip_limit'
    | 'system_booking_trip_limit_approved';
}

export type ManagerType = string;

export class WorkflowManagerBlock {
  @IsIn(['operational', 'legal'])
  @IsString()
  @IsNotEmpty()
  manager_type: 'operational' | 'legal';

  @IsUUID(undefined, { each: true })
  @IsArray()
  @IsNotEmpty()
  node_ids: string[];
}

export class WorkflowParticipantAction {
  @IsIn([
    'upload',
    'accept',
    'decline',
    'decline_sign',
    'sign',
    'system',
    'generate_document_from_template',
    'return',
    'competency_eval',
    'competency_profile',
    'system_booking_hook',
    'system_booking_approve',
    'system_booking_limits_exceeded_approve',
    'system_booking_trip_create',
    'system_booking_trip_ordering',
    'system_booking_trip_limit',
    'system_booking_trip_limit_approved',
  ])
  @IsString()
  @IsNotEmpty()
  action_type:
    | 'upload'
    | 'accept'
    | 'decline'
    | 'decline_sign'
    | 'sign'
    | 'system'
    | 'generate_document_from_template'
    | 'return'
    | 'competency_eval'
    | 'competency_profile'
    | 'system_booking_hook'
    | 'system_booking_approve'
    | 'system_booking_limits_exceeded_approve'
    | 'system_booking_trip_create'
    | 'system_booking_trip_ordering'
    | 'system_booking_trip_limit'
    | 'system_booking_trip_limit_approved';

  @IsInt()
  @IsNotEmpty()
  count: number;
}

export class WorkflowParticipant {
  @IsUUID(undefined, { each: true })
  @IsArray()
  @IsNotEmpty()
  id: string[];

  @IsString()
  @IsNotEmpty()
  role: string;

  @IsString()
  @IsOptional()
  group?: string;

  @IsString()
  @IsOptional()
  employee?: string;

  @IsString()
  @IsOptional()
  levels?: string;

  @IsInt()
  @IsOptional()
  number?: number;

  @IsBoolean()
  @IsNotEmpty()
  editable: boolean;

  @ValidateNested({ each: true })
  @Type(() => WorkflowParticipantAction)
  @IsArray()
  @IsNotEmpty()
  actions: WorkflowParticipantAction[];

  @IsBoolean()
  @IsNotEmpty()
  allow_delete: boolean;
}

export type DeadlineOutcome = string;

export type TimeUnit = string;

export type DeadlineReference = string;

export class DeadlineSettings {
  @IsIn([
    'event_deadline',
    'node_active',
    'attribute',
    'created_date',
    'created_datetime',
  ])
  @IsString()
  @IsNotEmpty()
  reference:
    | 'event_deadline'
    | 'node_active'
    | 'attribute'
    | 'created_date'
    | 'created_datetime';

  @IsIn(['minute', 'hour', 'day', 'workday'])
  @IsString()
  @IsNotEmpty()
  time_unit: 'minute' | 'hour' | 'day' | 'workday';

  @IsInt()
  @IsNotEmpty()
  term: number;

  @IsBoolean()
  @IsNotEmpty()
  round_days: boolean;

  @IsIn(['to_paper', 'cancel', 'skip'])
  @IsString()
  @IsNotEmpty()
  outcome: 'to_paper' | 'cancel' | 'skip';

  @ValidateNested()
  @Type(() => DeadlineSettingAttribute)
  @IsOptional()
  attribute?: DeadlineSettingAttribute;
}

export class WorkflowNodeAction {
  @IsString()
  @IsNotEmpty()
  name: string;

  @IsString()
  @IsOptional()
  document_type_name?: string;

  @IsIn([
    'upload',
    'accept',
    'decline',
    'decline_sign',
    'sign',
    'system',
    'generate_document_from_template',
    'return',
    'competency_eval',
    'competency_profile',
    'system_booking_hook',
    'system_booking_approve',
    'system_booking_limits_exceeded_approve',
    'system_booking_trip_create',
    'system_booking_trip_ordering',
    'system_booking_trip_limit',
    'system_booking_trip_limit_approved',
  ])
  @IsString()
  @IsNotEmpty()
  type:
    | 'upload'
    | 'accept'
    | 'decline'
    | 'decline_sign'
    | 'sign'
    | 'system'
    | 'generate_document_from_template'
    | 'return'
    | 'competency_eval'
    | 'competency_profile'
    | 'system_booking_hook'
    | 'system_booking_approve'
    | 'system_booking_limits_exceeded_approve'
    | 'system_booking_trip_create'
    | 'system_booking_trip_ordering'
    | 'system_booking_trip_limit'
    | 'system_booking_trip_limit_approved';

  @IsBoolean()
  @IsNotEmpty()
  is_primary: boolean;

  @IsBoolean()
  @IsNotEmpty()
  is_employee: boolean;

  @IsString({ each: true })
  @IsArray()
  @IsOptional()
  roles?: string[];
}

export class WorkflowNode {
  @IsUUID()
  @IsNotEmpty()
  id: string;

  @IsString()
  @IsNotEmpty()
  name: string;

  @ValidateNested({ each: true })
  @Type(() => WorkflowNodeAction)
  @IsArray()
  @IsNotEmpty()
  actions: WorkflowNodeAction[];

  @ValidateNested()
  @Type(() => DeadlineSettings)
  @IsOptional()
  deadline_settings?: DeadlineSettings;
}

export class Workflow {
  @ValidateNested({ each: true })
  @Type(() => WorkflowNode)
  @IsArray()
  @IsNotEmpty()
  nodes: WorkflowNode[];

  @ValidateNested({ each: true })
  @Type(() => WorkflowParticipant)
  @IsArray()
  @IsNotEmpty()
  participants: WorkflowParticipant[];

  @ValidateNested({ each: true })
  @Type(() => WorkflowManagerBlock)
  @IsArray()
  @IsNotEmpty()
  manager_blocks: WorkflowManagerBlock[];

  @ValidateNested({ each: true })
  @Type(() => WorkflowTransition)
  @IsArray()
  @IsNotEmpty()
  transitions: WorkflowTransition[];

  @ValidateNested({ each: true })
  @Type(() => Validator)
  @IsArray()
  @IsNotEmpty()
  validators: Validator[];
}

export class CompanyEventTypeItem {
  @IsUUID()
  @IsNotEmpty()
  id: string;

  @IsString()
  @IsNotEmpty()
  name: string;
}

export class GetEventTypeWorkflowResponse {
  @ValidateNested()
  @Type(() => CompanyEventTypeItem)
  @IsNotEmpty()
  event_type: CompanyEventTypeItem;

  @ValidateNested()
  @Type(() => Workflow)
  @IsNotEmpty()
  workflow: Workflow;
}

export type SignType = string;

export class CompanyEmployee {
  @IsUUID()
  @IsNotEmpty()
  id: string;

  @IsString()
  @IsNotEmpty()
  name: string;

  @Transform(({ value }) => 'hidden *****', { groups: ['sensitive'] })
  @IsString()
  @IsNotEmpty()
  first_name: string;

  @Transform(({ value }) => 'hidden *****', { groups: ['sensitive'] })
  @IsString()
  @IsNotEmpty()
  middle_name: string;

  @Transform(({ value }) => 'hidden *****', { groups: ['sensitive'] })
  @IsString()
  @IsNotEmpty()
  last_name: string;

  @ValidateNested()
  @Type(() => CompanyItem)
  @IsNotEmpty()
  company: CompanyItem;

  @Transform(({ value }) => 'hidden *****', { groups: ['sensitive'] })
  @IsString()
  @IsOptional()
  email?: string;

  @Transform(({ value }) => 'hidden *****', { groups: ['sensitive'] })
  @IsString()
  @IsOptional()
  phone?: string;

  @IsString()
  @IsNotEmpty()
  personnel_number: string;

  @IsString()
  @IsOptional()
  position?: string;

  @IsIn([
    'kontur',
    'goskey',
    'cryptopro_simple',
    'cryptopro_local',
    'disabled',
    'ukep',
  ])
  @IsString()
  @IsOptional()
  sign_type?:
    | 'kontur'
    | 'goskey'
    | 'cryptopro_simple'
    | 'cryptopro_local'
    | 'disabled'
    | 'ukep';
}

export class ManagerSubstitutesEventType {
  @ValidateNested()
  @Type(() => CompanyEventTypeItem)
  @IsNotEmpty()
  event_type: CompanyEventTypeItem;

  @ValidateNested({ each: true })
  @Type(() => CompanyEmployee)
  @IsArray()
  @IsNotEmpty()
  substitutes: CompanyEmployee[];
}

export class GetManagerSubstitutesResponse {
  @ValidateNested({ each: true })
  @Type(() => ManagerSubstitutesEventType)
  @IsArray()
  @IsNotEmpty()
  event_types: ManagerSubstitutesEventType[];
}

export class ManagerSubstitutesEmployee {
  @ValidateNested()
  @Type(() => CompanyEmployee)
  @IsNotEmpty()
  employee: CompanyEmployee;

  @IsInt()
  @IsNotEmpty()
  substitutes_count: number;
}

export class GetManagerSubstitutesListResponse {
  @ValidateNested({ each: true })
  @Type(() => ManagerSubstitutesEmployee)
  @IsArray()
  @IsNotEmpty()
  substitutes: ManagerSubstitutesEmployee[];

  @IsInt()
  @IsNotEmpty()
  total: number;
}

export type UnitManagerSettingApprove = string;

export class UnitManagerSetting {
  @ValidateNested()
  @Type(() => CompanyEventTypeItem)
  @IsNotEmpty()
  event_type: CompanyEventTypeItem;

  @IsIn(['always', 'direct_only', 'never'])
  @IsString()
  @IsNotEmpty()
  approve: 'always' | 'direct_only' | 'never';
}

export class Unit {
  @IsUUID()
  @IsNotEmpty()
  id: string;

  @IsString()
  @IsNotEmpty()
  name: string;

  @IsUUID()
  @IsOptional()
  parent_id?: string;

  @ValidateNested()
  @Type(() => CompanyEmployee)
  @IsOptional()
  manager?: CompanyEmployee;

  @ValidateNested({ each: true })
  @Type(() => UnitManagerSetting)
  @IsArray()
  @IsOptional()
  manager_settings?: UnitManagerSetting[];
}

export class UnitPathItem {
  @IsUUID()
  @IsNotEmpty()
  id: string;

  @IsString()
  @IsNotEmpty()
  name: string;
}

export class GetUnitResponse {
  @ValidateNested({ each: true })
  @Type(() => UnitPathItem)
  @IsArray()
  @IsNotEmpty()
  path: UnitPathItem[];

  @ValidateNested()
  @Type(() => Unit)
  @IsNotEmpty()
  unit: Unit;
}

export class CreateUnitResponse {
  @IsUUID()
  @IsNotEmpty()
  unit_id: string;
}

export class UnitNode {
  @IsUUID()
  @IsNotEmpty()
  id: string;

  @IsString()
  @IsNotEmpty()
  name: string;

  @ValidateNested()
  @Type(() => CompanyEmployee)
  @IsOptional()
  manager?: CompanyEmployee;

  @IsInt()
  @IsNotEmpty()
  employee_count: number;

  @IsBoolean()
  @IsNotEmpty()
  is_parent: boolean;

  @ValidateNested({ each: true })
  @Type(() => UnitNode)
  @IsArray()
  @IsOptional()
  children?: UnitNode[];
}

export class GetUnitChildrenResponse {
  @ValidateNested({ each: true })
  @Type(() => UnitNode)
  @IsArray()
  @IsOptional()
  units?: UnitNode[];
}

export class UnitsPermissions {
  @IsBoolean()
  @IsNotEmpty()
  edit_units: boolean;

  @IsBoolean()
  @IsNotEmpty()
  edit_manager_settings: boolean;
}

export class GetUnitsTreeResponse {
  @IsInt()
  @IsNotEmpty()
  total: number;

  @ValidateNested()
  @Type(() => UnitNode)
  @IsOptional()
  root_unit?: UnitNode;

  @ValidateNested()
  @Type(() => UnitsPermissions)
  @IsOptional()
  permissions?: UnitsPermissions;
}

export class FilterSelectBadge {
  @IsString()
  @IsNotEmpty()
  title: string;

  @IsString()
  @IsOptional()
  description?: string;

  @IsIn(['blue', 'velvet', 'red'])
  @IsString()
  @IsOptional()
  color?: 'blue' | 'velvet' | 'red';

  @IsIn(['left', 'bottom'])
  @IsString()
  @IsOptional()
  position?: 'left' | 'bottom';
}

export class FilterSelectOption {
  @IsString()
  @IsNotEmpty()
  value: string;

  @IsString()
  @IsNotEmpty()
  label: string;

  @ValidateNested()
  @Type(() => FilterSelectBadge)
  @IsOptional()
  badge?: FilterSelectBadge;
}

export type SegmentsOptionsResponse = FilterSelectOption[];

export class AbsenceFilterType {
  @IsIn(['vacation', 'sick_leave', 'remote_work', 'other'])
  @IsString()
  @IsNotEmpty()
  type: 'vacation' | 'sick_leave' | 'remote_work' | 'other';

  @IsString()
  @IsNotEmpty()
  description: string;
}

export class AbsenceFilterOption {
  @IsString()
  @IsNotEmpty()
  value: string;

  @IsString()
  @IsNotEmpty()
  label: string;

  @ValidateNested()
  @Type(() => AbsenceFilterType)
  @IsOptional()
  type?: AbsenceFilterType;
}

export type AbsencesOptionsResponse = AbsenceFilterOption[];

export type UnitsOptionsResponse = FilterSelectOption[];

export class UnitTypeItem {
  @IsString()
  @IsNotEmpty()
  name: string;

  @IsString()
  @IsNotEmpty()
  type: string;
}

export class GetUnitTypesResponse {
  @ValidateNested({ each: true })
  @Type(() => UnitTypeItem)
  @IsArray()
  @IsNotEmpty()
  types: UnitTypeItem[];
}

export class GetCompanyEmployeesResponse {
  @ValidateNested({ each: true })
  @Type(() => CompanyEmployee)
  @IsArray()
  @IsNotEmpty()
  employees: CompanyEmployee[];

  @IsInt()
  @IsNotEmpty()
  total_count: number;
}

export class GetCompanyEventTypesResponse {
  @ValidateNested({ each: true })
  @Type(() => CompanyEventTypeItem)
  @IsArray()
  @IsNotEmpty()
  event_types: CompanyEventTypeItem[];
}

export class GroupUserEmployee {
  @IsUUID()
  @IsNotEmpty()
  id: string;

  @ValidateNested()
  @Type(() => CompanyItem)
  @IsNotEmpty()
  company: CompanyItem;

  @IsString()
  @IsNotEmpty()
  personnel_number: string;
}

export class GroupUser {
  @IsUUID()
  @IsNotEmpty()
  id: string;

  @Transform(({ value }) => 'hidden *****', { groups: ['sensitive'] })
  @IsString()
  @IsNotEmpty()
  first_name: string;

  @Transform(({ value }) => 'hidden *****', { groups: ['sensitive'] })
  @IsString()
  @IsNotEmpty()
  last_name: string;

  @Transform(({ value }) => 'hidden *****', { groups: ['sensitive'] })
  @IsString()
  @IsNotEmpty()
  middle_name: string;

  @ValidateNested({ each: true })
  @Type(() => GroupUserEmployee)
  @IsArray()
  @IsNotEmpty()
  employees: GroupUserEmployee[];

  @ValidateNested({ each: true })
  @Type(() => ProfileItem)
  @IsArray()
  @IsOptional()
  profiles?: ProfileItem[];

  @IsBoolean()
  @IsNotEmpty()
  editable: boolean;
}

export class GroupUsers {
  @ValidateNested()
  @Type(() => GroupItem)
  @IsNotEmpty()
  group: GroupItem;

  @IsBoolean()
  @IsNotEmpty()
  employee_profiles_enabled: boolean;

  @ValidateNested({ each: true })
  @Type(() => GroupUser)
  @IsArray()
  @IsNotEmpty()
  users: GroupUser[];
}

export class CompanyGroupUsers {
  @ValidateNested()
  @Type(() => CompanyItem)
  @IsNotEmpty()
  company: CompanyItem;

  @ValidateNested({ each: true })
  @Type(() => GroupUsers)
  @IsArray()
  @IsNotEmpty()
  group_users: GroupUsers[];
}

export class EventStatItem {
  @IsInt()
  @IsNotEmpty()
  active: number;

  @IsInt()
  @IsNotEmpty()
  finished: number;

  @IsInt()
  @IsNotEmpty()
  canceled: number;

  @IsInt()
  @IsNotEmpty()
  in_paper: number;

  @IsInt()
  @IsNotEmpty()
  total: number;
}

export class EventTypeItem {
  @IsUUID()
  @IsNotEmpty()
  id: string;

  @IsString()
  @IsNotEmpty()
  name: string;

  @IsString()
  @IsNotEmpty()
  custom_type: string;
}

export class EventStat {
  @ValidateNested()
  @Type(() => EventTypeItem)
  @IsNotEmpty()
  event_type: EventTypeItem;

  @ValidateNested()
  @Type(() => EventStatItem)
  @IsNotEmpty()
  stat: EventStatItem;
}

export class CompanyEventsStat {
  @ValidateNested()
  @Type(() => CompanyItem)
  @IsNotEmpty()
  company: CompanyItem;

  @ValidateNested({ each: true })
  @Type(() => EventStat)
  @IsArray()
  @IsNotEmpty()
  events_stat: EventStat[];
}

export class CreateCompanyGroupResponseItem {
  @IsUUID()
  @IsNotEmpty()
  company_id: string;

  @IsBoolean()
  @IsNotEmpty()
  success: boolean;
}

export class CreateCompanyGroupResponse {
  @ValidateNested({ each: true })
  @Type(() => CreateCompanyGroupResponseItem)
  @IsArray()
  @IsNotEmpty()
  result: CreateCompanyGroupResponseItem[];
}

export class Group {
  @IsUUID()
  @IsNotEmpty()
  id: string;

  @IsString()
  @IsNotEmpty()
  name: string;

  @IsUUID()
  @IsNotEmpty()
  parent_id: string;
}

export class CompanyGroups {
  @ValidateNested({ each: true })
  @Type(() => Group)
  @IsArray()
  @IsNotEmpty()
  groups: Group[];
}

export class CheckCompanyGroupCreationResponseItem {
  @IsUUID()
  @IsNotEmpty()
  company_id: string;

  @IsIn(['success', 'already_exists', 'no_parent_group'])
  @IsString()
  @IsNotEmpty()
  status: 'success' | 'already_exists' | 'no_parent_group';
}

export class CheckCompanyGroupCreationResponse {
  @ValidateNested({ each: true })
  @Type(() => CheckCompanyGroupCreationResponseItem)
  @IsArray()
  @IsNotEmpty()
  result: CheckCompanyGroupCreationResponseItem[];
}

export class UpdateCompanyManagerBlocksResponse {
  @IsInt()
  @IsNotEmpty()
  updated_count: number;
}

export class EmployeeIdWithSignType {
  @IsIn([
    'kontur',
    'goskey',
    'cryptopro_simple',
    'cryptopro_local',
    'disabled',
    'ukep',
  ])
  @IsString()
  @IsNotEmpty()
  sign_type:
    | 'kontur'
    | 'goskey'
    | 'cryptopro_simple'
    | 'cryptopro_local'
    | 'disabled'
    | 'ukep';

  @IsUUID()
  @IsNotEmpty()
  id: string;

  @Transform(({ value }) => 'hidden *****', { groups: ['sensitive'] })
  @IsString()
  @IsNotEmpty()
  first_name: string;

  @Transform(({ value }) => 'hidden *****', { groups: ['sensitive'] })
  @IsString()
  @IsNotEmpty()
  last_name: string;

  @Transform(({ value }) => 'hidden *****', { groups: ['sensitive'] })
  @IsString()
  @IsNotEmpty()
  middle_name: string;

  @IsString()
  @IsNotEmpty()
  personnel_number: string;
}

export type EmployeeSignTypeSource = string;

export class EditableCompany {
  @IsString({ each: true })
  @IsArray()
  @IsNotEmpty()
  available_sign_types: string[];

  @IsIn(['web', 'onecvk', 'public'])
  @IsString()
  @IsNotEmpty()
  employee_sign_type_source: 'web' | 'onecvk' | 'public';

  @ValidateNested()
  @Type(() => CompanyItem)
  @IsNotEmpty()
  company_item: CompanyItem;

  @IsIn([
    'kontur',
    'goskey',
    'cryptopro_simple',
    'cryptopro_local',
    'disabled',
    'ukep',
  ])
  @IsString()
  @IsNotEmpty()
  sign_type:
    | 'kontur'
    | 'goskey'
    | 'cryptopro_simple'
    | 'cryptopro_local'
    | 'disabled'
    | 'ukep';

  @ValidateNested({ each: true })
  @Type(() => EmployeeIdWithSignType)
  @IsArray()
  @IsNotEmpty()
  user_with_sign_type: EmployeeIdWithSignType[];

  @IsBoolean()
  @IsOptional()
  import_operational_units?: boolean;

  @IsBoolean()
  @IsOptional()
  import_legal_units?: boolean;

  @IsBoolean()
  @IsNotEmpty()
  payslips_enabled: boolean;

  @IsBoolean()
  @IsOptional()
  update_managers_in_events?: boolean;

  @IsInt()
  @IsNotEmpty()
  employee_limit: number;

  @IsInt()
  @IsNotEmpty()
  employee_count: number;

  @IsBoolean()
  @IsNotEmpty()
  early_employee_event_access_enabled: boolean;
}

export class CompanyListResponse {
  @ValidateNested({ each: true })
  @Type(() => EditableCompany)
  @IsArray()
  @IsNotEmpty()
  companies: EditableCompany[];
}

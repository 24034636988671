import { createSelector } from '@reduxjs/toolkit';

import { Inject } from '@vk-hr-tek/core/ioc';
import { CalendarItem } from '@vk-hr-tek/ui/Calendar';

import {
  AbsenceEventCreationMapper,
  AbsencesListMapper,
  AbsencesMapper,
} from '../mappers';

import { AbsencesWithRootState as RootState } from './absences.state';

export const selectEmployeeAbsencesStatus = (state: RootState) =>
  state.absences.absenceList.status;

export const selectEmployeeAbsenceList = createSelector(
  [
    (state: RootState) => state.absences.absenceList.items,
    (_: RootState, inject: Inject) => inject(AbsencesMapper),
  ],
  (absences, format) => format.processEmployeeGroups(absences),
);

export const selectCompanyAbsenceList = createSelector(
  [
    (state: RootState) => state.absences.absenceList.items,
    (_: RootState, inject: Inject) => inject(AbsencesMapper),
  ],
  (absences, format) => format.processCompanyGroups(absences),
);

export const selectEmployeeClosestTask = createSelector(
  [
    (state: RootState) => state.absences.absenceList.items,
    (_: RootState, inject: Inject) => inject(AbsencesMapper),
  ],
  (absences, format) => format.processAbsenceClosestTask(absences),
);

export const selectCompaniesStatus = (state: RootState) =>
  state.absences.companies.status;

export const selectCompanies = (state: RootState) =>
  state.absences.companies.items;

export const selectCompanyCompanies = (state: RootState) =>
  state.absences.absenceCompanies.items;

export const selectAbsenceCompanies = (state: RootState) =>
  state.absences.absenceCompanies.items;

export const selectAbsenceCompaniesStatus = (state: RootState) =>
  state.absences.absenceCompanies.status;

export const selectEmployeeDeadline = (state: RootState) =>
  state.absences.deadline;

export const selectEmployeeCreationItems = createSelector(
  [
    (state: RootState) => state.absences.creation.items,
    (_: RootState, inject: Inject) => inject(AbsenceEventCreationMapper),
  ],
  (items, format) => (items ? format.processCreateEventItem(items) : []),
);

export const selectEmployeeCanCreateEvent = (state: RootState) =>
  state.absences.canCreateEvent;

export const selectEmployeeAbsencesGroupes = (state: RootState) =>
  state.absences.absenceList.items;

export const selectEmployeeEventCreationError = (state: RootState) =>
  state.absences.eventCreationError;

export const selectEmployeeAbsenceTypeItem = createSelector(
  [
    (state: RootState) => state.absences.absenceList.items,
    (_: RootState, inject: Inject) => inject(AbsencesMapper),
  ],
  (absences, format) =>
    format
      .processEmployeeGroups(absences)
      .filter((item: CalendarItem) => item.type === 'item'),
);

export const selectCompanyAbsencesStatus = (state: RootState) =>
  state.absences.absenceList.status;

export const selectCompanyTotal = (state: RootState) =>
  state.absences?.absenceList.total || 0;

export const selectAdditionalAbsencesStatus = (state: RootState) =>
  state.absences.absenceList.additionalStatus;

export const selectCompanyAbsencesFilters = createSelector(
  [
    (state: RootState) => state.absences.absenceList.filters,
    (state: RootState, inject: Inject, companyId: string) => companyId,
    (_: RootState, inject: Inject) => inject(AbsencesListMapper),
  ],
  (filters, companyId, format) =>
    filters && format.processListFilters(filters[companyId]),
);
export const selectVacationAvailableDays = (state: RootState) =>
  state.absences.availableDays.data.available_days_by_types;

export const selectVacationAvailableDaysStatus = (state: RootState) =>
  state.absences.availableDays.status;

export const selectCanCreateBatchEvent = (state: RootState) =>
  state.absences.creation.canCreateBatch;

export const selectCreationStatus = (state: RootState) =>
  state.absences.creation.status;

export const selectCreationError = (state: RootState) =>
  state.absences.creation.error;

export const selectCanCreateEventForAnotherEmployee = (state: RootState) =>
  state.absences.creation.canCreateForAnother;

export const selectCreationAbsenceEmployees = (state: RootState) =>
  state.absences.creation.employees.list;

export const selectCreationAbsenceEmployeesStatus = (state: RootState) =>
  state.absences.creation.employees.status;

export const selectCreationEventTypes = (state: RootState) =>
  state.absences.creation.eventTypes;

export const selectCreationEmployeeEventTypes = (state: RootState) =>
  state.absences.creation.employeeEventTypes;

export const selectCopyDocuments = createSelector(
  [
    (state: RootState) => state.absences.createEventTypeOptions.options,
    (_: RootState, inject: Inject, id: string) => id,
  ],
  (options, id) => options[id]?.copyDocuments || [],
);

export const selectEventCreateSelectableOptions = (state: RootState) =>
  state.absences.creation.selected;

export const selectAssignableRoles = createSelector(
  [
    (state: RootState) => state.absences.createEventTypeOptions.options,
    (_: RootState, inject: Inject) => inject(AbsenceEventCreationMapper),
    (_: RootState, inject: Inject, { id }: { id: string; required: boolean }) =>
      id,
    (
      _: RootState,
      inject: Inject,
      { required }: { id: string; required: boolean },
    ) => required,
  ],
  (options, mapper, id, required) =>
    mapper.processAssignableRoles(
      required
        ? options[id]?.assignableRoles?.required
        : options[id]?.assignableRoles?.optional,
      id,
      required,
    ),
);

export const selectCreateEventTypeOptionsStatus = (state: RootState) =>
  state.absences.createEventTypeOptions.status;

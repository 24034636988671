import React from 'react';

import { Form } from 'react-final-form';

import { useInject } from '@vk-hr-tek/core/ioc';
import { ValidationService } from '@vk-hr-tek/core/validation';
import { Box } from '@vk-hr-tek/ui/Box';
import {
  FormError,
  FormKnownValue,
  FormKnownValues,
  TextInput,
} from '@vk-hr-tek/ui/form';

import { useDispatch, useSelector } from '@app/hooks';

import {
  registerInit,
  selectNotification,
  selectSnils,
  selectStatus,
} from '../../../slice';
import { RegisterInitDto } from '../../../dto';
import { Button } from '../../../ui/Button';
import { useGetAuthInstance } from '../../../hooks';

export const RegisterInit = () => {
  const dispatch = useDispatch();
  const validator = useInject(ValidationService);

  const error = useSelector(selectNotification);
  const status = useSelector(selectStatus);
  const snils = useSelector(selectSnils);
  const authInstance = useGetAuthInstance();

  const { button_color: buttonColor } = authInstance;

  const onSubmit = (values: RegisterInitDto) =>
    new Promise((resolve) => {
      dispatch(
        registerInit({
          values,
          actions: { resolve },
        }),
      );
    });

  return (
    <div>
      <Form
        onSubmit={onSubmit}
        validate={(values) =>
          validator.validate(values, RegisterInitDto, ['form'])
        }
        render={({
          handleSubmit,
          submitting,
          pristine,
          hasValidationErrors,
          dirtySinceLastSubmit,
        }) => (
          <form onSubmit={handleSubmit}>
            <FormKnownValues>
              <FormKnownValue label="СНИЛС" value={snils} />
            </FormKnownValues>
            <Box mt="24">
              <TextInput
                name="phone"
                label="Номер телефона"
                placeholder="+7"
                mask="+7 (999) 999-99-99"
                id="qaAuthRegisterInitPhoneInput"
              />
            </Box>
            <Box mt="32">
              <Button
                color={buttonColor && `#${buttonColor}`}
                disabled={pristine || hasValidationErrors}
                loading={submitting}
              >
                Отправить код подтверждения
              </Button>
            </Box>
            {!dirtySinceLastSubmit && status && status === 'failed' && (
              <FormError error={error} />
            )}
          </form>
        )}
      />
    </div>
  );
};

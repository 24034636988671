import { IsNotEmpty, IsUUID } from 'class-validator';

import { NodeToSignDto } from './node-to-sign.dto';

export class CompanyBatchSignEventDto {
  @IsNotEmpty()
  @IsUUID()
  event_id: string;

  @IsNotEmpty()
  documents: NodeToSignDto[];
}

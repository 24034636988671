import React from 'react';

import MaterialContainer from '@material-ui/core/Container';
import classNames from 'classnames';

import { useIsDesktop } from '@vk-hr-tek/ui/hooks';
import { Typography } from '@vk-hr-tek/ui/Typography';
import { Box } from '@vk-hr-tek/ui/Box';

import { ContainerBackground } from './ContainerBackground';
import { useStyles } from './Container.styles';

export const Container = ({
  title,
  children,
  theme = 'general',
}: {
  title?: React.ReactNode;
  children: React.ReactNode | React.ReactNode[];
  theme?: 'general' | 'vk' | 'mobile' | 'ali' | 'oauth2';
}) => {
  const classes = useStyles();
  const isDesktop = useIsDesktop();

  return (
    <MaterialContainer className={isDesktop ? '' : classes.main_container}>
      {theme === 'vk' && <ContainerBackground />}
      <Box
        className={classNames(
          classes.container,
          theme === 'vk' && classes.container_theme_vk,
          theme === 'mobile' && classes.container_theme_mobile,
          theme === 'ali' && classes.container_theme_ali,
          theme === 'oauth2' && classes.container_theme_oauth2,
        )}
        pt={theme === 'ali' ? '40' : { xs: '0', md: '72' }}
        pb={theme === 'ali' ? '0' : '48'}
      >
        {title && (
          <Box pt={{ xs: '32', md: '0' }}>
            <Typography variant="h3">{title}</Typography>
          </Box>
        )}
        <div className={classes.content}>{children}</div>
      </Box>
    </MaterialContainer>
  );
};

import React from 'react';

import { useInject } from '@vk-hr-tek/core/ioc';
import { FormLinkButton } from '@vk-hr-tek/ui/form';
import { Box } from '@vk-hr-tek/ui/Box';
import { Typography } from '@vk-hr-tek/ui/Typography';

import { useSelector, useDispatch } from '@app/hooks';

import {
  selectNotification,
  clearNotification,
  selectAuthInstance,
} from '../../../slice';
import { AuthConfigService } from '../../../services';
import { Page } from '../../../ui/page';
import { LegalLinks } from '../../../ui/links';
import { Button } from '../../../ui/Button';

import { Notification } from './notification';

export const Login = () => {
  const config = useInject(AuthConfigService);
  const dispatch = useDispatch();

  const notification = useSelector(selectNotification);
  const { button_text: buttonText } = useSelector(selectAuthInstance);

  const showVpnNotification = config.getAuthType() === 'internalVpnOff';

  if (showVpnNotification) {
    return (
      <Page title={<Box textAlign="center">Ошибка соединения</Box>}>
        <Box textAlign="center">
          <Typography variant="body2" color="text.light.tertirary">
            Для входа в сервис электронного документооборота необходимо включить
            vpn или использовать корпоративную сеть wi-fi.
          </Typography>
        </Box>
      </Page>
    );
  }

  return (
    <Page
      title={
        <>
          Единый вход
          <br /> во все сервисы VK Team
        </>
      }
      notification={
        notification ? (
          <Box mb="32">
            <Notification onClose={() => dispatch(clearNotification())}>
              Отказ в авторизации
            </Notification>
          </Box>
        ) : (
          <Box width="100%" height={62} />
        )
      }
      theme="vk"
    >
      <Box mt="48" mb="16">
        <form
          action={`${config.getApiUrl()}/oauth2/auth_form/init`}
          method="POST"
        >
          <input type="hidden" value="false" name="phone_change" />
          <input type="hidden" value="vk_team" name="source" />
          <Button>{buttonText || 'Авторизоваться'}</Button>
        </form>
      </Box>

      <LegalLinks
        policyConfidentialityLink="policy_confidentiality"
        userAggrementLink="user_agreement"
      />

      <Box mt="24" mb="32" display="flex" justifyContent="center">
        <form
          action={`${config.getApiUrl()}/oauth2/auth_form/init`}
          method="POST"
        >
          <input type="hidden" value="true" name="phone_change" />
          <input type="hidden" value="vk_team" name="source" />
          <FormLinkButton>Изменился телефон</FormLinkButton>
        </form>
      </Box>
    </Page>
  );
};

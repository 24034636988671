import React from 'react';

import { Switch, Route, useRouteMatch } from 'react-router-dom';

import { PageError } from '../../../layout';
import { AuthById } from '../AuthById';

import { Login } from './Login';
import { Register } from './Register';

export const OpenId = () => {
  const match = useRouteMatch();

  return (
    <Switch>
      <Route exact path={`${match.url}/login`}>
        <Login />
      </Route>
      <Route exact path={`${match.url}/register`}>
        <Register />
      </Route>
      <Route path={`${match.url}/:id`}>
        <AuthById />
      </Route>
      <Route path={`${match.url}`}>
        <div>
          <PageError status={404} />
        </div>
      </Route>
    </Switch>
  );
};

import { createAsyncThunk } from '@reduxjs/toolkit';
import { classToPlain } from 'class-transformer';

import { AppError } from '@vk-hr-tek/core/error';
import { FormatService } from '@vk-hr-tek/core/format';
import { pollDownloadStatus } from '@vk-hr-tek/core/download-notification/slice';
import { setDownloadWaiting } from '@vk-hr-tek/core/download-notification';

import {
  CreateEventBatchDocumentZipResponse,
  EventXlsxResponse,
  EventPdfResponse,
} from '@app/gen/events';

import { ThunkExtra } from '../../../app/store';
import { EventsDownloadService, EventsListService } from '../../services';
import {
  BatchDownloadStartDto,
  GetEventsPdfDto,
  StartDownloadXlsxDto,
} from '../../dto';
import { setFilters } from '../actions';
import { EventsWithRootState } from '../../slice/events.state';

export const startDownload = createAsyncThunk<
  CreateEventBatchDocumentZipResponse,
  BatchDownloadStartDto,
  ThunkExtra<EventsWithRootState>
>(
  'events/startBatchDownload',
  async (
    batchDownloadStartDto: BatchDownloadStartDto,
    { rejectWithValue, dispatch, extra: { inject } },
  ) => {
    try {
      const service = inject(EventsDownloadService);

      const result = await service.startDownload(batchDownloadStartDto);

      await dispatch(
        setDownloadWaiting({
          id: result.download_id,
          count: batchDownloadStartDto.event_ids.length,
        }),
      );

      dispatch(pollDownloadStatus(result));

      return result;
    } catch (err) {
      return rejectWithValue(classToPlain(err) as AppError);
    }
  },
);

export const startDownloadXlsxByEvents = createAsyncThunk<
  EventXlsxResponse,
  StartDownloadXlsxDto,
  ThunkExtra<EventsWithRootState>
>(
  'events/startDownloadXlsxByEvents',
  async (
    startDownloadXlsxDto: StartDownloadXlsxDto,
    { rejectWithValue, getState, dispatch, extra: { inject } },
  ) => {
    try {
      const service = inject(EventsDownloadService);
      const eventsService = inject(EventsListService);
      const format = inject(FormatService);

      const state = getState().events;

      const { total } = state;

      let filters = state.filters;

      if (!filters.id || !filters.data) {
        filters = {
          data: await eventsService.getFilters(),
          id: '',
        };

        dispatch(setFilters(filters));
      }

      const result = await service.startDownloadXlsx(
        startDownloadXlsxDto,
        filters.data || [],
      );

      const formattedTotal = format.pluralize(total, [
        'заявка',
        'заявки',
        'заявок',
      ]);

      await dispatch(
        setDownloadWaiting({
          id: result.download_id,
          count: total,
          text: {
            loading: `Создаем файл (${formattedTotal})`,
            complete: `Файл создан (${formattedTotal})`,
            failed: 'Ошибка создания файла',
          },
        }),
      );

      dispatch(pollDownloadStatus(result));

      return result;
    } catch (err) {
      return rejectWithValue(classToPlain(err) as AppError);
    }
  },
);

export const startDownloadXlsxByDocuments = createAsyncThunk<
  EventXlsxResponse,
  StartDownloadXlsxDto,
  ThunkExtra<EventsWithRootState>
>(
  'events/startDownloadXlsxByDocuments',
  async (
    startDownloadXlsxDto: StartDownloadXlsxDto,
    { rejectWithValue, getState, dispatch, extra: { inject } },
  ) => {
    try {
      const service = inject(EventsDownloadService);
      const eventsService = inject(EventsListService);
      const format = inject(FormatService);

      const state = getState().events;

      const { total, totalDocuments } = state;

      let filters = state.filters;

      if (!filters.id || !filters.data) {
        filters = {
          data: await eventsService.getFilters(),
          id: '',
        };

        dispatch(setFilters(filters));
      }

      const result = await service.startDownloadXlsx(
        startDownloadXlsxDto,
        filters.data || [],
        true,
      );

      const formattedTotal = format.pluralize(total, [
        'заявка',
        'заявки',
        'заявок',
      ]);
      const formattedTotalDocuments = format.pluralize(totalDocuments || 0, [
        'документ',
        'документа',
        'документов',
      ]);

      await dispatch(
        setDownloadWaiting({
          id: result.download_id,
          count: total,
          text: {
            loading: `Создаем файл (${formattedTotal}${
              totalDocuments ? `, ${formattedTotalDocuments}` : ''
            })`,
            complete: `Файл создан  (${formattedTotal}${
              totalDocuments ? `, ${formattedTotalDocuments}` : ''
            })`,
            failed: 'Ошибка создания файла',
          },
        }),
      );

      dispatch(pollDownloadStatus(result));

      return result;
    } catch (err) {
      return rejectWithValue(classToPlain(err) as AppError);
    }
  },
);

export const startDownloadEventsPdfDocument = createAsyncThunk<
  EventPdfResponse,
  GetEventsPdfDto,
  ThunkExtra<EventsWithRootState>
>(
  'events/startDownloadEventsPdf',
  async (
    getEventsPdfDto: GetEventsPdfDto,
    { rejectWithValue, getState, dispatch, extra: { inject } },
  ) => {
    try {
      const service = inject(EventsDownloadService);
      const eventsService = inject(EventsListService);
      const format = inject(FormatService);

      const state = getState().events;

      const { total } = state;

      const totalSelected = getEventsPdfDto.event_ids.length;

      let filters = state.filters;

      if (!filters.id || !filters.data) {
        filters = {
          data: await eventsService.getFilters(),
          id: '',
        };

        dispatch(setFilters(filters));
      }

      const result = await service.startDownloadEventsPdf(getEventsPdfDto);

      const formattedTotal = format.pluralize(
        totalSelected ? totalSelected : total,
        ['заявка', 'заявки', 'заявок'],
      );
      const formattedTotalDocuments = format.pluralize(
        totalSelected ? totalSelected : total || 0,
        ['документ', 'документа', 'документов'],
      );

      await dispatch(
        setDownloadWaiting({
          id: result.download_id,
          count: total,
          text: {
            loading: `Создаем файл (${formattedTotal}, ${formattedTotalDocuments})`,
            complete: `Файл создан  (${formattedTotal}, ${formattedTotalDocuments})`,
            failed: 'Ошибка создания файла',
          },
        }),
      );

      dispatch(pollDownloadStatus(result));

      return result;
    } catch (err) {
      return rejectWithValue(classToPlain(err) as AppError);
    }
  },
);

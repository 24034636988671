import { IsNotEmpty, IsString, IsUUID } from 'class-validator';

export class GetAbsenceListServiceDto {
  @IsUUID()
  @IsString()
  @IsNotEmpty()
  companyId: string;

  @IsString()
  @IsNotEmpty()
  year: number;
}

import { IsNotEmpty, IsUUID } from 'class-validator';

import { FormAttributesRequest } from '../../types';

import { DetailNodeToSignDto } from './detail-node-to-sign.dto';

export class SignUnepLocalDto {
  @IsNotEmpty()
  @IsUUID()
  eventId: string;

  @IsNotEmpty()
  @IsUUID()
  nodeId: string;

  attributes?: FormAttributesRequest;

  signatures: DetailNodeToSignDto[];
}

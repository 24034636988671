import { IsNotEmpty, IsUUID, IsOptional, IsString } from 'class-validator';

export class GetEventBatchOptionsDto {
  @IsUUID()
  @IsNotEmpty()
  companyId: string;

  @IsUUID()
  @IsNotEmpty()
  eventTypeId: string;

  @IsUUID()
  @IsOptional()
  unitId?: string;

  @IsString()
  @IsOptional()
  employeeId?: string;
}

import {
  ActionReducerMapBuilder,
  createAction,
  createAsyncThunk,
  EntityState,
} from '@reduxjs/toolkit';
import { classToPlain } from 'class-transformer';
import { FORM_ERROR } from 'final-form';

import {
  UnitProcessor,
  UnitService,
  UnitNodeLabeled,
  UnitType,
  UnitTypeOption,
} from '@vk-hr-tek/core/units';
import { QueryService } from '@vk-hr-tek/core/query';
import { History } from '@vk-hr-tek/core/history';
import { ValidationService } from '@vk-hr-tek/core/validation';
import { AppError } from '@vk-hr-tek/core/error';
import { showNotification } from '@vk-hr-tek/core/notifications';
import { AbsenceListGroup } from '@vk-hr-tek/app/app/gen/absences';

import { ThunkExtra } from '@app/store';
import {
  CreateEventCompanyItem,
  CreateEventTypeOptions,
  EventBatchEmployee,
  EventBatchItem,
  EventTypeItem,
} from '@app/gen/events';

import { AbsencesState, AbsencesWithRootState } from '..';
import { CreateEventBatchDto, GetEventBatchOptionsDto } from '../../dto';
import { AbsencesService } from '../../services';
import { AbsencesRouter } from '../../types';

export const setCreateItems = createAction<CreateEventCompanyItem[]>(
  'events/setCreateItems',
);

export const setCreateEventTypes = createAction<EventTypeItem[]>(
  'events/setCreateEventTypes',
);

export const getCreateEventTypeOptions = createAsyncThunk<
  CreateEventTypeOptions,
  { event_type_id: string },
  ThunkExtra<AbsencesWithRootState>
>(
  'createEvent/getCreateEventTypeOptions',
  async (
    getCreateEventTypeOptionsDto,
    { rejectWithValue, getState, extra: { inject } },
  ) => {
    try {
      const {
        events: { createEventTypeOptions },
      } = getState();
      const { event_type_id: eventTypeId } = getCreateEventTypeOptionsDto;
      const optionById = createEventTypeOptions.options[eventTypeId];

      if (optionById) {
        return {
          assignable_roles: optionById.assignableRoles,
          copy_documents: optionById.copyDocuments,
          copy_attributes: optionById.copyAttributes,
        };
      }

      const service = inject(AbsencesService);
      const result = await service.getCreateEventTypeOptions(
        getCreateEventTypeOptionsDto,
      );

      return result;
    } catch (err) {
      return rejectWithValue(classToPlain(err) as AppError);
    }
  },
);

export const getBatchOptionsCompany = createAsyncThunk<
  {
    company: { id: string; name: string };
    eventType: { id: string; name: string; documentInfoRequired: boolean };
    employees: EventBatchEmployee[];
    existingBatches: EventBatchItem[];
    unitTypeOptions?: UnitTypeOption[];
    unitType: UnitType | null;
    rootUnit: UnitNodeLabeled | null;
    employeesLoadingError: boolean;
  },
  undefined,
  ThunkExtra<AbsencesWithRootState>
>(
  'createEvent/getBatchOptionsCompany',
  async (_, { rejectWithValue, getState, dispatch, extra: { inject } }) => {
    try {
      const history = inject<History>(History);
      const batchOptions = inject(QueryService).parse(
        history.location.search,
      ) as GetEventBatchOptionsDto;

      const validator = inject(ValidationService);

      await validator.validateOrReject(batchOptions, GetEventBatchOptionsDto);

      const service = inject(AbsencesService);
      const unitService = inject(UnitService);
      const unitProcessor = inject(UnitProcessor);
      const state = getState().absences.creation;

      let items = state.items;

      dispatch(
        getCreateEventTypeOptions({
          event_type_id: batchOptions.eventTypeId,
        }),
      );

      if (!items || !items.length) {
        const res = await service.getCompanies({
          withEventTypes: true,
        });

        items = res;

        dispatch(setCreateItems(items));
      }

      const selectedCompany = items.find(
        (item) => item.company_id === batchOptions.companyId,
      );

      if (!selectedCompany) {
        throw new AppError('client', {
          code: 400,
          message: 'Bad Request',
          error: 'Bad Request',
        });
      }

      let eventTypes = state.eventTypes;

      if (!eventTypes || !eventTypes.length) {
        const res = await service.getCreateBatchEventTypes({
          companyId: batchOptions.companyId,
        });

        eventTypes = res.event_types;

        dispatch(setCreateEventTypes(eventTypes));
      }

      const selectedEventType = eventTypes.find(
        (eventType) => eventType.id === batchOptions.eventTypeId,
      );

      if (!selectedEventType) {
        throw new AppError('client', {
          code: 400,
          message: 'Bad Request',
          error: 'Bad Request',
        });
      }

      const { event_batches: existingBatches } =
        await service.getExistingBatchEvents(batchOptions);

      const { types } = await unitService.getUnitTypes(batchOptions);

      let rootUnit = null;
      let unitType = null;

      if (Array.isArray(types) && types.length) {
        unitType = types[0].type as UnitType;
        const unitTree = await unitService.getUnitTree({
          companyId: batchOptions.companyId,
          unitType,
        });

        rootUnit = unitTree.root_unit ?? null;
      }

      const { employees } = await service.getCreateBatchEmployees(batchOptions);

      if (!Array.isArray(employees)) {
        throw new AppError('client', {
          code: 400,
          message: 'Bad Request',
          error: 'Bad Request',
        });
      }

      return {
        company: {
          id: selectedCompany.company_id,
          name: selectedCompany.company_name,
        },
        eventType: {
          id: selectedEventType.id,
          name: selectedEventType.name,
          documentInfoRequired: !!selectedEventType.document_info_required,
        },
        employees,
        existingBatches: existingBatches || [],
        unitTypeOptions: types.map(
          ({ type: value, name: label }) =>
            ({
              value,
              label,
            } as UnitTypeOption),
        ),
        unitType,
        rootUnit: rootUnit ? unitProcessor.processUnitsTree(rootUnit) : null,
        employeesLoadingError: false,
      };
    } catch (err) {
      return rejectWithValue(classToPlain(err) as AppError);
    }
  },
);

export const getUpdateOfBatchOptionsCompanyForUnitType = createAsyncThunk<
  {
    rootUnit: UnitNodeLabeled | null;
    employees: EventBatchEmployee[];
  },
  { unitType: UnitType; companyId: string },
  ThunkExtra<AbsencesWithRootState>
>(
  'createEvent/updateUnitType',
  async ({ unitType, companyId }, { rejectWithValue, extra: { inject } }) => {
    try {
      const history = inject<History>(History);
      const service = inject(AbsencesService);
      const batchOptions = inject(QueryService).parse(
        history.location.search,
      ) as GetEventBatchOptionsDto;

      const unitService = inject(UnitService);
      const unitProcessor = inject(UnitProcessor);

      const { root_unit: rootUnit = null } = await unitService.getUnitTree({
        companyId,
        unitType,
      });

      const { employees } = await service.getCreateBatchEmployees(batchOptions);

      return {
        employees,
        rootUnit: rootUnit ? unitProcessor.processUnitsTree(rootUnit) : null,
      };
    } catch (err) {
      return rejectWithValue(classToPlain(err) as AppError);
    }
  },
);

export const createBatchEvents = createAsyncThunk<
  void,
  {
    values: CreateEventBatchDto;
    actions: {
      resolve: (value: unknown) => void;
      reject: (value: unknown) => void;
    };
  },
  ThunkExtra<AbsencesWithRootState>
>(
  'createEvent/createBatchEvents',
  async (
    { values, actions },
    { rejectWithValue, dispatch, extra: { inject } },
  ) => {
    try {
      const history = inject<History>(History);
      await inject(AbsencesService).createBatchEvents(values);

      dispatch(
        showNotification(
          `Заявки (${values.employees.length}) успешно созданы!`,
        ),
      );
      actions.resolve(null);
      const location = history.location as {
        state?: { prev?: { pathname: string; search: string }[] };
      };

      if (location.state?.prev) {
        inject<AbsencesRouter>(AbsencesRouter).goToList(
          location.state.prev?.[0]?.search,
        );
      } else {
        inject<AbsencesRouter>(AbsencesRouter).goToList();
      }
    } catch (err) {
      actions.reject({ [FORM_ERROR]: err });
      return rejectWithValue(classToPlain(err) as AppError);
    }
  },
);

export const getEmployeesForUnit = createAsyncThunk<
  { employees: EventBatchEmployee[] },
  GetEventBatchOptionsDto,
  ThunkExtra<AbsencesWithRootState>
>(
  'createEvent/updateUnit',
  async (batchOptions, { rejectWithValue, extra: { inject } }) => {
    try {
      const service = inject(AbsencesService);

      const result = await service.getCreateBatchEmployees(batchOptions);

      return result;
    } catch (err) {
      return rejectWithValue(classToPlain(err) as AppError);
    }
  },
);

export const creationReducers = (
  builder: ActionReducerMapBuilder<
    EntityState<AbsenceListGroup> & AbsencesState
  >,
) => {
  builder.addCase(setCreateItems, (state, action) => {
    state.creation.items = action.payload;
  });

  builder.addCase(setCreateEventTypes, (state, action) => {
    state.creation.eventTypes = action.payload;
  });

  builder.addCase(getCreateEventTypeOptions.pending, (state) => {
    state.createEventTypeOptions.status = 'loading';
    state.createEventTypeOptions.error = null;
  });
  builder.addCase(
    getCreateEventTypeOptions.fulfilled,
    (state, { payload, meta }) => {
      state.createEventTypeOptions.status = 'complete';
      state.createEventTypeOptions.options[meta.arg.event_type_id] = {
        ...(state.createEventTypeOptions.options[meta.arg.event_type_id] || {}),
        assignableRoles: payload.assignable_roles,
        copyDocuments: payload.copy_documents,
        copyAttributes: payload.copy_attributes,
      };
      state.createEventTypeOptions.error = null;
    },
  );
  builder.addCase(
    getCreateEventTypeOptions.rejected,
    (state, { payload, error, meta }) => {
      if (!meta.aborted) {
        state.createEventTypeOptions.status = 'failed';
        state.createEventTypeOptions.error =
          payload ||
          ({
            info: (error && error.message) || 'Что-то пошло не так',
            status: 500,
            source: 'client',
            title: 'Internal client error',
          } as AppError);
      }
    },
  );

  builder.addCase(getBatchOptionsCompany.pending, (state) => {
    state.creation.status = 'loading';
    state.creation.selected = null;
    state.creation.error = null;
  });
  builder.addCase(getBatchOptionsCompany.fulfilled, (state, { payload }) => {
    state.creation.status = 'complete';
    state.creation.selected = payload;
    state.creation.error = null;
  });
  builder.addCase(
    getBatchOptionsCompany.rejected,
    (state, { payload, error }) => {
      state.creation.status = 'failed';
      state.creation.selected = null;
      state.creation.error =
        payload ||
        ({
          info: (error && error.message) || 'Что-то пошло не так',
          status: 500,
          source: 'client',
          title: 'Internal client error',
        } as AppError);
    },
  );

  builder.addCase(
    getUpdateOfBatchOptionsCompanyForUnitType.pending,
    (state) => {
      state.creation.error = null;
      if (state.creation.selected) {
        state.creation.selected.employees = [];
        state.creation.selected.rootUnit = null;
      }
    },
  );
  builder.addCase(
    getUpdateOfBatchOptionsCompanyForUnitType.fulfilled,
    (state, { payload }) => {
      if (state.creation.selected) {
        const { rootUnit, employees } = payload;
        state.creation.selected.rootUnit = rootUnit;
        state.creation.selected.employees = employees;
        state.creation.selected.employeesLoadingError =
          !Array.isArray(employees);
      }
      state.creation.error = null;
    },
  );

  builder.addCase(
    getUpdateOfBatchOptionsCompanyForUnitType.rejected,
    (state, { payload, error }) => {
      state.creation.status = 'failed';
      state.creation.error =
        payload ||
        ({
          info: (error && error.message) || 'Что-то пошло не так',
          status: 500,
          source: 'client',
          title: 'Internal client error',
        } as AppError);
    },
  );

  builder.addCase(getEmployeesForUnit.pending, (state) => {
    state.creation.error = null;
    if (state.creation.selected) {
      state.creation.selected.employees = [];
    }
  });
  builder.addCase(getEmployeesForUnit.fulfilled, (state, { payload }) => {
    if (state.creation.selected) {
      const { employees } = payload;
      state.creation.selected.employees = employees;
      state.creation.selected.employeesLoadingError = !Array.isArray(employees);
    }
    state.creation.error = null;
  });
  builder.addCase(getEmployeesForUnit.rejected, (state, { payload, error }) => {
    state.creation.status = 'failed';
    state.creation.error =
      payload ||
      ({
        info: (error && error.message) || 'Что-то пошло не так',
        status: 500,
        source: 'client',
        title: 'Internal client error',
      } as AppError);
  });
};

import { useEffect } from 'react';

import { useInject } from '@vk-hr-tek/core/ioc';

import { useDispatch, useIsWidget, useSelector } from '@app/hooks';

import {
  AuthConfigService,
  AuthInstanseService,
  AuthOpenIdService,
} from '../services';
import {
  getAuthInstance,
  selectAuthInstance,
  selectAuthInstanceError,
  selectAuthInstanceStatus,
  selectAuthInstances,
} from '../slice';

export const useAuthType = () => {
  const dispatch = useDispatch();
  const config = useInject(AuthConfigService);
  const openIdConfig = useInject(AuthOpenIdService);
  const authInstanseService = useInject(AuthInstanseService);
  const isWidget = useIsWidget();

  const status = useSelector(selectAuthInstanceStatus);
  const error = useSelector(selectAuthInstanceError);
  const authInstance = useSelector(selectAuthInstance);
  const authInstances = useSelector(selectAuthInstances);

  let localAuthType = 'unknown';

  if (isWidget) {
    localAuthType = openIdConfig.get() ? 'openid' : 'default';
  } else {
    localAuthType = config.getAuthType();
  }

  useEffect(() => {
    if (
      (localAuthType === 'unknown' ||
        localAuthType === 'openid' ||
        localAuthType === 'default') &&
      ['idle'].includes(status)
    ) {
      dispatch(getAuthInstance());
    }
  }, [dispatch, localAuthType, status, authInstance?.auth_type]);

  if (isWidget) {
    const openidInstanse = authInstances?.find(
      (item) => item.auth_type === 'openid',
    );
    const defaultInstanse = authInstances?.find(
      (item) => item.auth_type === 'default',
    );
    if (localAuthType === 'openid' && openIdConfig.get() && openidInstanse) {
      authInstanseService.save(openidInstanse?.id);
    } else {
      authInstanseService.save(defaultInstanse?.id || '');
      localAuthType = 'default';
    }
  }

  return {
    status: localAuthType !== 'unknown' ? 'complete' : status,
    error,
    authType:
      localAuthType !== 'unknown' ? localAuthType : authInstance?.auth_type,
    oauth2Source: authInstance?.oauth2_source,
  };
};

import { IsString, IsNotEmpty, IsUUID } from 'class-validator';

import { IsSnils } from '@vk-hr-tek/core/validation';

const passCodeValidationOptions = {
  groups: ['load'],
  message: 'Неверный qr-код',
};

export class VerifyPassCodeDto {
  @IsSnils({
    groups: ['form'],
    message: 'Неверный СНИЛС',
  })
  @IsString({
    groups: ['form'],
    message: 'Вы забыли ввести СНИЛС',
  })
  @IsNotEmpty({
    message: 'Вы забыли ввести СНИЛС',
    groups: ['form'],
  })
  snils?: string;

  @IsUUID(undefined, passCodeValidationOptions)
  @IsString(passCodeValidationOptions)
  @IsNotEmpty(passCodeValidationOptions)
  passCode?: string;

  @IsUUID()
  @IsString()
  @IsNotEmpty()
  authInstanceId?: string;
}

import { Type } from 'class-transformer';
import {
  IsArray,
  IsIn,
  IsNotEmpty,
  IsOptional,
  IsString,
  IsUUID,
} from 'class-validator';

import { AssignedRole } from '../../types';

export class CreateEventDto {
  @IsString({
    groups: ['form', 'form-single', 'form-batch'],
  })
  @IsNotEmpty({
    message: 'Обязательное поле',
    groups: ['form', 'form-single', 'form-batch'],
  })
  companyId: string;

  @IsUUID(undefined, {
    groups: ['form', 'form-single', 'form-batch', 'submit'],
  })
  @IsNotEmpty({
    message: 'Обязательное поле',
    groups: ['form', 'form-single', 'form-batch', 'submit'],
  })
  eventTypeId: string;

  @IsString({ groups: ['form', 'form-single', 'submit'] })
  @IsNotEmpty({
    message: 'Обязательное поле',
    groups: ['form', 'form-single', 'submit'],
  })
  employeeId: string;

  @IsIn(['single', 'batch'], { groups: ['form-single', 'form-batch'] })
  @IsString({ groups: ['form-single', 'form-batch'] })
  @IsNotEmpty({
    message: 'Обязательное поле',
    groups: ['form-single', 'form-batch'],
  })
  creationType: string;

  @IsIn(['self', 'another'], {
    groups: ['form-employee'],
  })
  @IsString({ groups: ['form-employee'] })
  @IsNotEmpty({
    message: 'Обязательное поле',
    groups: ['form-employee'],
  })
  employeeCreationType: string;

  @Type(() => AssignedRole)
  @IsArray()
  @IsOptional()
  assigned_roles?: AssignedRole[];

  @IsUUID()
  @IsOptional()
  parentEventId?: string;
}

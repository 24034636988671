import { Type } from 'class-transformer';
import { IsArray, IsNotEmpty, ValidateNested, IsString } from 'class-validator';

import { DetailPolicyVersion } from '../detail/detail-policy-version.dto';

const notEmptyOptions = {
  message: 'Обязательное поле',
};

export class AcceptCryptoUkepDto {
  @Type(() => DetailPolicyVersion)
  @ValidateNested({ each: true })
  @IsArray()
  @IsNotEmpty(notEmptyOptions)
  policyVersions: DetailPolicyVersion[];

  @IsString()
  @IsNotEmpty()
  certificateId: string;
}

import { IsNotEmpty, IsOptional, IsUUID } from 'class-validator';

export class GetCompanyEventTypesDto {
  @IsUUID()
  @IsNotEmpty()
  employeeId: string;

  @IsUUID()
  @IsOptional()
  companyId?: string;
}

/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect } from 'react';

import { useInject } from '@vk-hr-tek/core/ioc';

import { useDispatch } from '../../../../app/hooks';
import { PageLoading } from '../../../../layout';
import { useAuth } from '../../../hooks';
import { AuthRouterService } from '../../../services';
import { verifyOAuth2Code } from '../../../slice';
import { AuthInstanceContext } from '../../AuthById';

export const VerifyAuthCode = () => {
  const dispatch = useDispatch();
  const isAuthenticated = useAuth();
  const router = useInject(AuthRouterService);
  const authInstanceIdFromContext = useContext(AuthInstanceContext);

  useEffect(() => {
    if (isAuthenticated) {
      router.redirectToSaved();
    }
  }, [isAuthenticated, router]);

  useEffect(() => {
    if (!isAuthenticated) {
      dispatch(verifyOAuth2Code(authInstanceIdFromContext));
    }
  }, [isAuthenticated, dispatch]);

  return <PageLoading />;
};

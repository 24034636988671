export type ValidateType = (
  value: any,
  allValues?: any,
) => (string | undefined) | Promise<string | undefined>;

export const composeValidators =
  (validators: ValidateType[]) =>
  (value: Record<string, any> | any, allValues: Record<string, any>) => {
    const result = validators.reduce(
      (error: any, validator?: ValidateType) =>
        error || validator?.(value, allValues),
      undefined,
    );
    return result;
  };

/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect } from 'react';

import { useInject } from '@vk-hr-tek/core/ioc';

import { useDispatch, useSelector } from '../../../../app/hooks';
import { PageLoading } from '../../../../layout';
import { useAuth } from '../../../hooks';
import {
  verifyPassCode,
  selectStage,
  selectNotification,
  selectAuthInstance,
} from '../../../slice';
import { PageError } from '../../../ui/page';
import { AuthRouterService } from '../../../services';
import { AuthInstanceContext } from '../../AuthById';

export const VerifyPassCode = () => {
  const dispatch = useDispatch();
  const step = useSelector(selectStage);
  const error = useSelector(selectNotification);
  const isAuthenticated = useAuth();
  const router = useInject(AuthRouterService);
  const authInstance = useSelector(selectAuthInstance);
  const authInstanceId = useContext(AuthInstanceContext);

  useEffect(() => {
    if (isAuthenticated) {
      router.redirectToSaved();
    }
  }, [isAuthenticated, router]);

  useEffect(() => {
    if (!isAuthenticated) {
      dispatch(verifyPassCode(authInstanceId || authInstance.id));
    }
  }, [isAuthenticated, dispatch]);

  if (step === 'error') {
    return <PageError theme="general" error={error} />;
  }

  return <PageLoading />;
};

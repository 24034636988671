/* eslint-disable react/jsx-props-no-spreading */
import React, { useCallback } from 'react';

import { FieldProps, FieldRenderProps, useField } from 'react-final-form';
import { Collapse } from '@material-ui/core';

import { TextInput as Input } from '../../input';

import { Autorecognition } from './Autorecognition';

type ValidateType = (
  value: any,
  allValues?: any,
) => (string | undefined) | Promise<string | undefined>;

const TextInput = ({
  label,
  name,
  onChange,
  type,
  mask,
  placeholder,
  multiline = false,
  rows = 1,
  showError = true,
  required = false,
  tooltip,
  after,
  disabled = false,
  loading = false,
  clearable = false,
  recognizedValue,
  isRecognitionUsedBefore = false,
  validationWithoutTouch = false,
  inputMode,
  autocomplete,
  testId,
  validateArray,
  ...rest
}: { after?: (value: string | undefined) => React.ReactNode } & FieldProps<
  string | undefined,
  FieldRenderProps<string | undefined> & {
    validateArray?: ValidateType[] | undefined;
  }
>) => {
  const composeValidators =
    (validators: ValidateType[]) =>
    (value: Record<string, any> | any, allValues: Record<string, any>) => {
      const result = validators.reduce(
        (error: any, validator?: ValidateType) =>
          error || validator?.(value, allValues),
        undefined,
      );
      return result;
    };

  const { input, meta } = useField(name, {
    validate: composeValidators(validateArray || []),
    ...rest,
  });

  const { onBlur, onChange: onInputChange } = input;

  const handleChange = useCallback(
    (value: string | undefined) => {
      onInputChange(value);

      if (onChange) {
        onChange(value);
      }
    },
    [onInputChange, onChange],
  );

  const renderError = validationWithoutTouch
    ? !!input.value && !!meta.error
    : meta.touched && !!meta.error;

  return (
    <div>
      <Autorecognition
        recognizedValue={recognizedValue}
        isRecognitionUsedBefore={isRecognitionUsedBefore}
        onBlur={onBlur}
        disabled={disabled}
        setValue={input.onChange}
        value={input.value}
        mask={mask}
      >
        <Input
          {...input}
          testId={testId}
          label={label}
          required={required}
          disabled={disabled}
          value={input.value}
          onChange={handleChange}
          type={type}
          error={!disabled && renderError}
          helperText={!disabled && showError && renderError && meta.error}
          placeholder={placeholder}
          tooltip={tooltip}
          multiline={multiline}
          rows={rows}
          mask={mask}
          loading={loading}
          clearable={clearable}
          inputMode={inputMode}
          autocomplete={autocomplete}
        />
        {after && typeof after === 'function' && (
          <Collapse in={meta.visited}>{after(input.value)}</Collapse>
        )}
      </Autorecognition>
    </div>
  );
};

export default TextInput;

import {
  createBrowserHistory,
  History as HistoryInterface,
  createMemoryHistory,
} from 'history';

import { removeBasename } from '../path';

const PREVHISTORY_STACK_LIMIT = 10;

export const createHistoryWidthPrev =
  (
    historyCreator: typeof createBrowserHistory | typeof createMemoryHistory,
    role?: string,
    basenames?: string[],
  ) =>
  /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
  (...args: any) => {
    const historyInitiated = historyCreator(...args);
    const prevPush = historyInitiated.push;

    const prevStateStack: Array<{ pathname: string; search: string }> = [];

    const pushToPrevHistoryStack = (prevState: {
      pathname: string;
      search: string;
    }) => {
      if (prevStateStack.length === PREVHISTORY_STACK_LIMIT) {
        prevStateStack.pop();
      }
      prevStateStack.unshift(prevState);
    };

    const locationWithoutRole = (pathname: string) => {
      if (
        basenames &&
        basenames.length > 1 &&
        role &&
        basenames.some(
          (basename, index) => !!index && pathname.startsWith(basename),
        )
      ) {
        return pathname.split(`/${role}`)[1];
      }
      return pathname;
    };

    historyInitiated.push = (location) => {
      if (
        basenames &&
        basenames.length > 1 &&
        historyInitiated.location.pathname === '/'
      ) {
        pushToPrevHistoryStack({
          pathname: basenames[0],
          search: historyInitiated.location.search,
        });
      } else {
        pushToPrevHistoryStack({
          pathname: historyInitiated.location.pathname,
          search: historyInitiated.location.search,
        });
      }
      if (typeof location === 'string') {
        location = locationWithoutRole(location);

        prevPush(removeBasename(location, basenames), {
          prev: prevStateStack,
        });
      } else {
        if (location?.pathname) {
          location.pathname = locationWithoutRole(location.pathname);
        }

        prevPush({
          ...location,
          ...(location.pathname
            ? { pathname: removeBasename(location.pathname, basenames) }
            : {}),
          state: {
            ...Object.assign({}, location?.state),
            prev: prevStateStack,
          },
        });
      }
    };

    return historyInitiated as HistoryInterface;
  };

export type History = HistoryInterface;
export const history = createHistoryWidthPrev(createBrowserHistory)();
export const memoryHistory = createHistoryWidthPrev(createMemoryHistory)();
/* eslint-disable-next-line @typescript-eslint/no-redeclare */
export const History = Symbol('History');

import { useLocation } from 'react-router-dom';

import { useInject } from '@vk-hr-tek/core/ioc';

import { useSelector, useIsWidget } from '@app/hooks';

import { selectAuthInstance, selectAuthInstanceById } from '../slice';
import { AuthInstanseService } from '../services';

export const useGetAuthInstance = () => {
  const location = useLocation();
  const authInstanseService = useInject(AuthInstanseService);
  const isWidget = useIsWidget();

  const authInstance = useSelector(selectAuthInstance);
  const authInstanceById = useSelector(
    selectAuthInstanceById,
    isWidget ? authInstanseService.get() : location?.pathname?.split('/')[2],
  );

  return authInstanceById || authInstance;
};

import { IsNotEmpty, IsString, IsUUID } from 'class-validator';

export class CreateEventFromAbsenceDto {
  @IsUUID()
  @IsString()
  @IsNotEmpty()
  employeeId: string;

  @IsUUID()
  @IsString()
  @IsNotEmpty()
  eventTypeId: string;

  attributes: Record<string, string | number>;
}

import React from 'react';

import ArrowBackIcon from '@material-ui/icons/ArrowBackIos';
import { useHistory } from 'react-router-dom';

import { Box } from '@vk-hr-tek/ui/Box';
import { Link } from '@vk-hr-tek/ui/Link';
import { Typography } from '@vk-hr-tek/ui/Typography';
import { GosUslugiIcon } from '@vk-hr-tek/ui/icons';

import { useSelector } from '../../../app/hooks';
import { selectAuthInstancesWithoutOpenId } from '../../slice';
import { Page } from '../../ui/page';
import { Button } from '../../ui/Button';

export const AuthList = () => {
  const authInstances = useSelector(selectAuthInstancesWithoutOpenId);
  const history = useHistory();

  const handleGoAdditionalAuth = (id: string) => {
    history.push(`/auth/${id}/login`);
  };

  return (
    <Page title="Войти другим способом">
      <Box>
        <Box
          alignItems="center"
          justifyContent="center"
          display="flex"
          flexDirection="column"
          gap="24"
          width="100%"
        >
          {authInstances.map((item, i) => {
            if (i === 0) {
              return null;
            }
            if (item.auth_type === 'oauth2' && item.oauth2_source === 'esia') {
              return (
                <Box
                  py="16"
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  border={1}
                  radius="l"
                  borderColor="stroke.primary"
                  width="100%"
                  onClick={() => handleGoAdditionalAuth(item.id || '')}
                  cursor="pointer"
                  key={item.id}
                >
                  <Typography variant="h6">
                    <Box display="flex" gap="8" alignItems="center">
                      <GosUslugiIcon />
                      Войти через Госуслуги
                    </Box>
                  </Typography>
                </Box>
              );
            }
            return (
              <Button
                key={item.id}
                fullWidth
                color={item.button_color && `#${item.button_color}`}
                onClick={() => handleGoAdditionalAuth(item.id || '')}
              >
                {item.button_text || 'Войти'}
              </Button>
            );
          })}
        </Box>
        <Box mt="32" display="flex" alignItems="center">
          <Link
            size="small"
            variant="tertiary"
            startIcon={<ArrowBackIcon />}
            href="/auth"
          >
            Вернуться назад
          </Link>
        </Box>
      </Box>
    </Page>
  );
};

import { injectable } from 'inversify';

import { FormatService } from '@vk-hr-tek/core/format';

import {
  FormMetaObjectStep,
  FormMetaObjectDocument,
  FormMetaObjectBlock,
  FormMetaObjectSubBlock,
  FormMetaObjectRow,
  FormMetaObjectAttribute,
  Attribute as FormAttribute,
} from '@app/gen/events';

import {
  FileFormAttribute,
  FormMetaObjectLayoutExtended,
  FormMetaObjectDocumentExtended,
  FormMetaObjectBlockExtended,
  FormAttributeFiles,
  AttributeValueBase as AttributeBase,
  FormMeta,
  FormMetaObjectSubBlockExtended,
  FormMetaObjectSubBlockLayoutExtended,
} from '../types';

@injectable()
export class EventsAttributesMetaMapper {
  constructor(private format: FormatService) {}

  getMetaAttributeIds(
    metas: (
      | FormMetaObjectStep
      | FormMetaObjectDocument
      | FormMetaObjectBlock
      | FormMetaObjectSubBlock
      | FormMetaObjectRow
      | FormMetaObjectAttribute
    )[],
  ): Record<string, boolean> {
    return metas.reduce((result, meta) => {
      if (meta.type === 'attribute') {
        return {
          ...result,
          [meta.id]: true,
        };
      }

      return {
        ...result,
        ...this.getMetaAttributeIds(meta.objects),
      };
    }, {});
  }

  getMetaAttributes(
    metas: (
      | FormMetaObjectStep
      | FormMetaObjectDocument
      | FormMetaObjectBlock
      | FormMetaObjectSubBlock
      | FormMetaObjectRow
      | FormMetaObjectAttribute
    )[],
  ): Record<string, FormMetaObjectAttribute & { attribute?: FormAttribute }> {
    return metas.reduce((result, meta) => {
      if (meta.type === 'attribute') {
        return {
          ...result,
          [meta.id]: meta,
        };
      }

      return {
        ...result,
        ...this.getMetaAttributes(meta.objects),
      };
    }, {});
  }

  findUnusedAttributes<T extends AttributeBase = FormAttribute>(
    metas: (
      | FormMetaObjectStep
      | FormMetaObjectDocument
      | FormMetaObjectBlock
      | FormMetaObjectRow
      | FormMetaObjectAttribute
    )[],
    formAttributes: T[],
  ) {
    const metaAttributeIds = this.getMetaAttributeIds(metas);

    return formAttributes.filter(({ id }) => !metaAttributeIds[id]);
  }

  findUsedAttributes<T extends AttributeBase = FormAttribute>(
    metas: (
      | FormMetaObjectStep
      | FormMetaObjectDocument
      | FormMetaObjectSubBlock
      | FormMetaObjectAttribute
    )[],
    formAttributes: T[],
  ) {
    const metaAttributeIds = this.getMetaAttributeIds(metas);

    return formAttributes.filter(({ id }) => metaAttributeIds[id]);
  }

  findFileAttributes<T extends AttributeBase = FormAttribute>(
    formAttributes: T[],
  ) {
    return formAttributes.filter(
      (attribute): attribute is FileFormAttribute<T> =>
        attribute.type === 'file' || attribute.type === 'file_multiple',
    );
  }

  findNoneFileAttributes<T extends AttributeBase = FormAttribute>(
    formAttributes: T[],
  ) {
    return formAttributes.filter(
      (attribute): attribute is FileFormAttribute<T> =>
        attribute.type !== 'file' && attribute.type !== 'file_multiple',
    );
  }

  processMetaStep<T extends AttributeBase = FormAttribute>(
    step: FormMetaObjectStep,
    formAttributes: T[],
  ) {
    const initialValue: (
      | FormMetaObjectBlockExtended<T>
      | FormMetaObjectLayoutExtended<T>
    )[] = [];

    return {
      ...step,
      objects: step.objects.reduce((acc, meta) => {
        if (meta.type === 'block') {
          return [...acc, this.processMetaBlock(meta, formAttributes)];
        }

        return [...acc, ...this.processMetaDocument(meta, formAttributes)];
      }, initialValue),
    };
  }

  getMultipleElementsLength<T extends AttributeBase = FormAttribute>(
    formAttributes: T[],
  ) {
    return formAttributes.reduce((acc, attribute) => {
      if (Array.isArray(attribute.value) && attribute.value.length > acc) {
        return attribute.value.length;
      }

      return acc;
    }, 0);
  }

  getMultipleAttributesByIndex<T extends AttributeBase = FormAttribute>(
    formAttributes: T[],
    index: number,
  ): T[] {
    return formAttributes
      .map((attribute) => {
        if (Array.isArray(attribute.value) && attribute.value[index]) {
          return {
            ...attribute,
            value: attribute.value[index],
          } as T;
        }

        return null;
      })
      .filter((attribute): attribute is T => attribute !== null);
  }

  processSingleDocument<T extends AttributeBase = FormAttribute>(
    document: FormMetaObjectDocument,
    formAttributes: T[],
  ): FormMetaObjectLayoutExtended<T> {
    const result: (
      | FormMetaObjectDocumentExtended<T>
      | FormAttributeFiles<T>
    )[] = [];

    const usedAttributes = this.findUsedAttributes([document], formAttributes);
    const fileAttributes = this.findFileAttributes(usedAttributes);
    const noneFileAttributes = this.findNoneFileAttributes(usedAttributes);

    result.push({
      ...document,
      objects: document.objects
        .map((meta) => {
          if (meta.type === 'block') {
            return this.processMetaBlock(meta, noneFileAttributes);
          }

          if (meta.type === 'row') {
            return this.processMetaRow(meta, noneFileAttributes);
          }

          return this.processMetaAttribute(meta, noneFileAttributes);
        })
        .filter(
          (item) =>
            item.type === 'attribute' ||
            (item.type === 'block' && item.objects.length) ||
            (item.type === 'row' && item.objects.length),
        ),
    });

    if (fileAttributes.length) {
      result.push({
        type: 'files',
        attributes: fileAttributes,
      });
    }

    return {
      type: 'layout',
      title: document.title,
      objects: result.filter(
        (item) =>
          (item.type === 'document' && item.objects.length) ||
          item.type !== 'document',
      ),
    };
  }

  processMetaDocument<T extends AttributeBase = FormAttribute>(
    document: FormMetaObjectDocument,
    formAttributes: T[],
  ): FormMetaObjectLayoutExtended<T>[] {
    const result: FormMetaObjectLayoutExtended<T>[] = [];

    const usedAttributes = this.findUsedAttributes([document], formAttributes);

    if (document.is_multiple) {
      const documentsCount = this.getMultipleElementsLength(usedAttributes);
      for (let i = 0; i < documentsCount; i++) {
        result.push(
          this.processSingleDocument(
            document,
            this.getMultipleAttributesByIndex(usedAttributes, i),
          ),
        );
      }
    } else {
      result.push(this.processSingleDocument(document, usedAttributes));
    }

    return result;
  }

  processMetaBlock<T extends AttributeBase = FormAttribute>(
    block: FormMetaObjectBlock,
    formAttributes: T[],
  ): FormMetaObjectBlockExtended<T> {
    const result = {
      ...block,
      objects: block.objects
        .map((meta) => {
          if (meta.type === 'row') {
            return this.processMetaRow(meta, formAttributes);
          }

          if (meta.type === 'subblock') {
            return this.processMetaSubBlock(meta, formAttributes);
          }

          return this.processMetaAttribute(
            meta as unknown as FormMetaObjectAttribute,
            formAttributes,
          );
        })
        .filter(
          (meta) =>
            (meta.type === 'row' && meta.objects.length) ||
            (meta.type === 'subBlockLayout' && meta.objects.length) ||
            (meta.type === 'attribute' && meta.attribute),
        ),
    };

    return result;
  }

  processSingleSubBlock<T extends AttributeBase = FormAttribute>(
    subBlock: FormMetaObjectSubBlock,
    formAttributes: T[],
  ) {
    return {
      ...subBlock,
      objects: subBlock.objects
        .map((meta) => {
          if (meta.type === 'row') {
            return this.processMetaRow(meta, formAttributes);
          }

          return this.processMetaAttribute(
            meta as unknown as FormMetaObjectAttribute,
            formAttributes,
          );
        })
        .filter(
          (meta) =>
            (meta.type === 'row' && meta.objects.length) ||
            (meta.type === 'attribute' && meta.attribute),
        ),
    };
  }

  processMetaSubBlock<T extends AttributeBase = FormAttribute>(
    subBlock: FormMetaObjectSubBlock,
    formAttributes: T[],
  ): FormMetaObjectSubBlockLayoutExtended<T> {
    const result: FormMetaObjectSubBlockExtended<T>[] = [];
    const usedAttributes = this.findUsedAttributes([subBlock], formAttributes);

    if (subBlock.is_multiple) {
      const subBlocksCount = this.getMultipleElementsLength(usedAttributes);

      for (let i = 0; i < subBlocksCount; i++) {
        result.push(
          this.processSingleSubBlock(
            subBlock,
            this.getMultipleAttributesByIndex(usedAttributes, i),
          ),
        );
      }
    } else {
      result.push(this.processSingleSubBlock(subBlock, formAttributes));
    }

    return {
      type: 'subBlockLayout',
      objects: result.filter(
        (item) => item.type === 'subblock' && item.objects.length,
      ),
    };
  }

  processMetaRow<T extends AttributeBase = FormAttribute>(
    row: FormMetaObjectRow,
    formAttributes: T[],
  ) {
    return {
      ...row,
      objects: row.objects
        .map((meta) => {
          return this.processMetaAttribute(meta, formAttributes);
        })
        .filter(
          ({ attribute }) =>
            attribute &&
            attribute.type !== 'file' &&
            attribute.type !== 'file_multiple',
        ),
    };
  }

  processMetaAttribute<T extends AttributeBase = FormAttribute>(
    metaAttribute: FormMetaObjectAttribute,
    formAttributes: T[],
  ) {
    const attribute =
      formAttributes.find(({ id }) => id === metaAttribute.id) || null;

    if (attribute?.type === 'date' && attribute?.value) {
      return {
        ...metaAttribute,
        attribute: {
          ...attribute,
          value: this.format.toDate(attribute.value as string),
        },
      };
    }

    return {
      ...metaAttribute,
      attribute,
    };
  }

  removeStepsFromMeta(
    formMeta: (
      | FormMetaObjectStep
      | FormMetaObjectDocument
      | FormMetaObjectBlock
    )[],
  ) {
    let result: (FormMetaObjectDocument | FormMetaObjectBlock)[] = [];

    formMeta.forEach((meta) => {
      if (meta.type === 'step') {
        result = [...result, ...meta.objects];
        return;
      }

      result = [...result, meta];
    });

    return result;
  }

  processFormMeta<T extends AttributeBase = FormAttribute>(
    formMeta: (
      | FormMetaObjectStep
      | FormMetaObjectDocument
      | FormMetaObjectBlock
    )[],
    formAttributes: T[],
    skipSteps = true,
  ) {
    const metas = skipSteps ? this.removeStepsFromMeta(formMeta) : formMeta;
    const initialValue: FormMeta<T>[] = [];

    const result = metas
      .reduce((acc, meta) => {
        if (meta.type === 'step') {
          return [...acc, this.processMetaStep(meta, formAttributes)];
        }

        if (meta.type === 'document') {
          return [...acc, ...this.processMetaDocument(meta, formAttributes)];
        }

        return [...acc, this.processMetaBlock(meta, formAttributes)];
      }, initialValue)
      .filter((item) => item.objects.length);

    return result;
  }
}

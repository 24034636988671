import { IsOptional, IsString, IsBoolean, IsNotEmpty } from 'class-validator';

export class GetCreateOptionsCompanyDto {
  @IsBoolean()
  @IsNotEmpty()
  withEventTypes: boolean;

  @IsString()
  @IsOptional()
  eventTypeGroup?: string;
}

import React from 'react';

import { Box } from '@vk-hr-tek/ui/Box';
import { Typography } from '@vk-hr-tek/ui/Typography';

import { useStyles } from './DividerWithText.styles';

interface DividerWithTextProps {
  label: string;
}

export const DividerWithText = ({ label }: DividerWithTextProps) => {
  const classes = useStyles();

  return (
    <Box display="flex" alignItems="center" className={classes.divider}>
      <Box mx="24">
        <Typography variant="body3" color="text.light.tertirary">
          {label}
        </Typography>
      </Box>
    </Box>
  );
};

import { injectable } from 'inversify';

import { HttpService } from '@vk-hr-tek/core/http';
import { UnmarshallerService } from '@vk-hr-tek/core/unmarshaller';
import { ValidationService } from '@vk-hr-tek/core/validation';
import { FilterService } from '@vk-hr-tek/core/filter';

import {
  AvailableAbsenceFilters,
  AbsenceListResponse,
  AbsenceCompanyOptionsList,
} from '@app/gen/absences';

import { GetCompanyAbsencesListDto } from '../dto';

@injectable()
export class AbsencesListService {
  constructor(
    private http: HttpService,
    private unmarshaller: UnmarshallerService,
    private validator: ValidationService,
    private filterService: FilterService,
  ) {}

  async getCompanyAbsencesList(
    getCompanyAbsencesListDto: GetCompanyAbsencesListDto,
  ) {
    const { limit, offset, filters, companyId, year, month } =
      getCompanyAbsencesListDto;

    const requestFilters = { ...filters, company_id: companyId, year, month };

    const result = await this.unmarshaller.unmarshall(
      await this.http.post(
        '/absences/company/list',
        {
          pagination: {
            limit,
            offset,
          },
          filters: requestFilters,
        },
        {
          withSide: true,
        },
      ),
      AbsenceListResponse,
    );

    return result;
  }

  async getEmployeeAbsencesList(
    getEmployeeAbsencesListDto: GetCompanyAbsencesListDto,
  ) {
    const { limit, offset, filters, companyId, year, month } =
      getEmployeeAbsencesListDto;

    const requestFilters = { ...filters, company_id: companyId, year, month };

    const result = await this.unmarshaller.unmarshall(
      await this.http.post(
        '/absences/employee/list',
        {
          pagination: {
            limit,
            offset,
          },
          filters: requestFilters,
        },
        {
          withSide: true,
        },
      ),
      AbsenceListResponse,
    );

    return result;
  }

  async getAbsenceFilters(companyId: string) {
    const result = await this.unmarshaller.unmarshall(
      await this.http.get(
        `/absence_available_filters/${companyId}`,
        {},
        { withSide: true },
      ),
      AvailableAbsenceFilters,
    );

    return result.filters;
  }

  async getAbsenceCompanies() {
    const result = await this.unmarshaller.unmarshall(
      await this.http.get('/absences/companies', {}, { withSide: true }),
      AbsenceCompanyOptionsList,
    );

    return result.items;
  }
}

import { injectable } from 'inversify';

import { UnmarshallerService } from '@vk-hr-tek/core/unmarshaller';
import { Logger } from '@vk-hr-tek/core/logger';
import { AppError } from '@vk-hr-tek/core/error';

import { OpenIdTokenVerifyResponse } from '@app/gen/users';

@injectable()
export class AuthOpenIdVerifyService {
  constructor(
    private readonly unmarshaller: UnmarshallerService,
    private logger: Logger,
  ) {}

  async verify({
    token,
    authInstanceId,
  }: {
    token: string;
    authInstanceId: string;
  }) {
    const response = await fetch('/openid/token/verify', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ token, auth_instance_id: authInstanceId }),
    });

    if (!response.ok) {
      let errorResponse: Record<string, any> = {};

      try {
        errorResponse = await response.clone().json();
      } catch (e) {}

      const traceId = response.headers.get('X-Mayday-Trace-Id');

      this.logger.info(
        `[http] [${response.status}] POST /openid/token/verify`,
        {
          tags: {
            vkdoc_error_type: 'http',
            http_method: 'POST',
            http_status: response.status,
            http_url: '/openid/token/verify',
            http_trace_id: traceId || 'empty',
            http_error_code: errorResponse?.data?.error_code || 'empty',
            http_side: 'empty',
            http_version: 'empty',
          },
          context: {
            http_body: null,
            http_query: null,
            http_response: {
              value: errorResponse,
            },
          },
        },
      );

      throw new AppError(
        'server',
        {
          code: errorResponse.code || response.status,
          message: errorResponse.message,
          data: errorResponse.data,
        },
        traceId,
      );
    }

    let parsedJson = {};

    try {
      parsedJson = await response.json();
    } catch {}

    const result = await this.unmarshaller.unmarshall(
      parsedJson,
      OpenIdTokenVerifyResponse,
    );

    return result;
  }
}

import React, { useEffect } from 'react';

import { useParams } from 'react-router-dom';

import { Box } from '@vk-hr-tek/ui/Box';
import { CircularProgress } from '@vk-hr-tek/ui/CircularProgress';
import { HTTP_BAD_REQUEST_STATUS } from '@vk-hr-tek/core/http';

import { useSelector, useDispatch } from '@app/hooks';

import { GeneralAuth, OAuth2, Esia, OpenId } from '../../pages';
import { PageError } from '../../../layout';
import {
  selectAuthInstanceById,
  selectAuthInstances,
  getAuthInstance,
  selectAuthInstanceStatus,
  selectAuthInstanceError,
} from '../../slice';
import { useAuthType } from '../../hooks';
import { VKAuth } from '../vk';
import { AliAuth } from '../ali';

import { AuthInstanceContext } from './AuthInstanceContext';

export const AuthById = () => {
  const { id } = useParams<{ id?: string }>();
  const dispatch = useDispatch();

  const authInstance = useSelector(selectAuthInstanceById, id);
  const authInstances = useSelector(selectAuthInstances);
  const status = useSelector(selectAuthInstanceStatus);
  const error = useSelector(selectAuthInstanceError);

  const { authType } = useAuthType();

  useEffect(() => {
    if (authInstances.length === 0) {
      dispatch(getAuthInstance());
    }
  }, [authInstances.length, dispatch]);

  if (status === 'idle' || status === 'loading')
    return (
      <Box display="flex" justifyContent="center" p="32">
        <CircularProgress size={50} />
      </Box>
    );

  if (status === 'failed')
    return (
      <PageError
        status={error?.status || HTTP_BAD_REQUEST_STATUS}
        errorMessage={error?.message}
      />
    );

  if (authType === 'vk') {
    return <VKAuth />;
  }

  if (!authInstance?.auth_type) {
    return <PageError status={404} />;
  }

  switch (authInstance?.auth_type) {
    case 'pass': {
      return (
        <AuthInstanceContext.Provider value={id || ''}>
          <AliAuth />
        </AuthInstanceContext.Provider>
      );
    }

    case 'openid': {
      return (
        <AuthInstanceContext.Provider value={id || ''}>
          <OpenId />
        </AuthInstanceContext.Provider>
      );
    }
    case 'oauth2': {
      if (authInstance.oauth2_source === 'keycloak') {
        return (
          <AuthInstanceContext.Provider value={id || ''}>
            <OAuth2 />
          </AuthInstanceContext.Provider>
        );
      }
      if (authInstance.oauth2_source === 'esia') {
        return (
          <AuthInstanceContext.Provider value={id || ''}>
            <Esia />
          </AuthInstanceContext.Provider>
        );
      }

      return (
        <AuthInstanceContext.Provider value={id || ''}>
          <GeneralAuth />
        </AuthInstanceContext.Provider>
      );
    }

    default:
      return (
        <AuthInstanceContext.Provider value={id || ''}>
          <GeneralAuth />
        </AuthInstanceContext.Provider>
      );
  }
};

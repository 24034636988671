import React, { useCallback } from 'react';

import { Box } from '@vk-hr-tek/ui/Box';
import { CircularProgress } from '@vk-hr-tek/ui/CircularProgress';
import { Typography } from '@vk-hr-tek/ui/Typography';
import { useIsDesktop } from '@vk-hr-tek/ui/hooks';
import { useInject } from '@vk-hr-tek/core/ioc';
import { FormLinkButton } from '@vk-hr-tek/ui/form';
import { AuthConfigService } from '@vk-hr-tek/app/auth/services';

import { Page } from '../../../ui/page';
import { Logo } from '../../../../layout/ui/Logo';
import { Button } from '../../../ui/Button';
import { AdditionalAuthButton } from '../../../widgets';
import { useGetAuthInstance } from '../../../hooks';

export const Login = () => {
  const config = useInject(AuthConfigService);
  const isDesktop = useIsDesktop();

  const {
    button_color: buttonColor,
    button_text: buttonText,
    id,
  } = useGetAuthInstance();

  const oauth2AutoSubmit =
    window.REACT_APP_VKHRTEK_OAUTH2_AUTO_SUBMIT.toString() === 'true';

  const formRef = useCallback(
    (element: HTMLFormElement) => {
      if (oauth2AutoSubmit && element) {
        element.submit();
      }
    },
    [oauth2AutoSubmit],
  );

  return (
    <Page title="" theme="oauth2">
      <Box>
        <form
          action={`${config.getApiUrl()}/oauth2/auth_form/init`}
          method="POST"
          ref={formRef}
        >
          <Box display="flex" flexDirection="column" alignItems="center">
            <Logo variant={isDesktop ? '80' : '72'} />
            <Box
              mt={{ xs: '32', md: '40' }}
              mb={{ xs: '32', md: '32' }}
              gap="16"
              display="flex"
              flexDirection="column"
              alignItems="center"
            >
              <Typography variant={isDesktop ? 'h4' : 'h5'} align="center">
                Добро пожаловать!
              </Typography>
              <Typography
                variant={isDesktop ? 'body1' : 'body3'}
                align="center"
              >
                Это сервис кадрового электронного документооборота (КЭДО)
              </Typography>
            </Box>
            <input type="hidden" value="false" name="phone_change" />
            <input type="hidden" value="keycloak" name="source" />
            <input type="hidden" value={id} name="auth_instance_id" />
            {oauth2AutoSubmit ? (
              <CircularProgress size={50} />
            ) : (
              <Button
                color={buttonColor && `#${buttonColor}`}
                fullWidth={!isDesktop}
              >
                {buttonText || 'Войти'}
              </Button>
            )}
            <AdditionalAuthButton />
          </Box>
        </form>
      </Box>
      <Box mt="16" display="flex" justifyContent="center">
        <Box py="4">
          <form
            action={`${config.getApiUrl()}/oauth2/auth_form/init`}
            method="POST"
          >
            <input type="hidden" value="true" name="phone_change" />
            <input type="hidden" value="keycloak" name="source" />
            <input type="hidden" value={id} name="auth_instance_id" />
            <FormLinkButton variant="tertiary">
              Изменился телефон
            </FormLinkButton>
          </form>
        </Box>
      </Box>
    </Page>
  );
};

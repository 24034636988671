import { IsArray, IsNotEmpty, IsUUID } from 'class-validator';

export class CreateSubstituteDto {
  @IsUUID()
  @IsNotEmpty()
  substituteId: string;

  @IsUUID()
  @IsNotEmpty()
  employeeId: string;

  @IsArray()
  @IsNotEmpty()
  eventTypes: string[];
}

/* eslint-disable @typescript-eslint/no-inferrable-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Type } from 'class-transformer';
import {
  IsInt,
  IsUUID,
  IsNotEmpty,
  IsOptional,
  ValidateNested,
  Min,
} from 'class-validator';

import { Filters } from './filters.dto';

export class GetPolicyVersionEmployeesDto {
  @IsUUID()
  @IsNotEmpty()
  id: string;

  @IsUUID()
  @IsNotEmpty()
  versionId: string;

  @IsOptional()
  @IsInt()
  @Min(0)
  @Type(() => Number)
  offset: number = 0;

  @IsOptional()
  @IsInt()
  @Min(1)
  @Type(() => Number)
  // eslint-disable-next-line no-magic-numbers
  limit: number = 20;

  @IsOptional()
  @ValidateNested()
  @Type(() => Filters)
  filters?: Filters;
}
